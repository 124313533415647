import React from 'react';
import './pricingpage.css'; // Import your CSS file for styling

const enterprisePrices = [
    { range: "Required Storage Database", price: "The intention of discussing a possible price for industries benfit" },
  ];

const individualPrices = [
  { range: "Upto 5 MB", price: "Free" },
  { range: "Upto 10 MB", price: "99/-" },
  { range: "Upto 20 MB", price: "199/-" },
  { range: "Upto 30 MB", price: "299/-" },
  { range: "Upto 50 MB", price: "499/-" },
    { range: "Upto 1 GB", price: "999/-" },
    { range: "Upto 2 GB", price: "1599/-" },
    { range: "Upto 3 GB", price: "1999/-" },
    { range: "Upto 5 GB", price: "2999/-" },
    { range: "Upto 10 GB", price: "5999/-" },
    { range: "Upto 15 GB", price: "9999/-" },
    { range: "Upto 20 GB", price: "12999/-" },
    { range: "Upto 25 GB", price: "15999/-" },
    { range: "Upto 30 GB", price: "19999/-" },
    { range: "Upto 35 GB", price: "22999/-" },
    { range: "Upto 40 GB", price: "25999/-" },
    { range: "Upto 45 GB", price: "29999/-" },
    { range: "Upto 50 GB", price: "34999/-" },
    { range: "Upto 60 GB", price: "44999/-" },
    { range: "Upto 70 GB", price: "54999/-" },
    { range: "Upto 80 GB", price: "64999/-" },
    { range: "Upto 90 GB", price: "74999/-" },
    { range: "Upto 1 TB", price: "79999/-" },
  ];

const PricingPage = () => {
  return (
    <>
    <div class="card" style={{backgroundColor:"yellowgreen",position:"sticky",top:"8%"}}>
    <h3>Annual renewal price 99rs  for  ONE  GB </h3>
    </div>
   
    <div className="pricing-page">
    <h1 className="title">Pricing Plans</h1>
 
    <div className="pricing-section">
      <h2 className="category-title">Individual</h2>
      <table className="pricing-table">
        <thead>
          <tr>
            <th>Storage</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {individualPrices.map((item, index) => (
            <tr key={index}>
              <td>{item.range}</td>
              <td>{item.price}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  </>
  );
}

export default PricingPage;
{/* <div className="pricing-section">
<h2 className="category-title">Industry</h2>
<table className="pricing-table">
  <thead>
    <tr>
      <th>Storage</th>
      <th>Price</th>
    </tr>
  </thead>
  <tbody>
    {enterprisePrices.map((item, index) => (
      <tr key={index}>
        <td>{item.range}</td>
        <td>{item.price}</td>
      </tr>
    ))}
  </tbody>
</table>
</div> */}