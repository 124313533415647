import React from "react";
import "../styles/comingSoon.css";

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
     
    </div>
  );
};

export default ComingSoon;
