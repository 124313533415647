import React from 'react';

import '../../styles/Terms.css'
const TermsOfService = () => (
  <div className="terms-of-service">
    <h1>Terms of Service</h1>
    <p>Welcome to ZM QRCode Services. By using our services, you agree to comply with the following terms and conditions. Please read them carefully.</p>

    <h2> INTELLECTUAL PROPERTY</h2>
    <p>Our Site, Service and related content (and any derivative works or enhancements of the same) including, but not limited to, all text, illustrations, files, images, software, scripts, graphics, photos, sounds, music, videos, information, content, materials, products, services, URLs, technology, documentation and interactive features (collectively, "Site Content") and all intellectual property rights to the same are owned by us, our licensors, or both. Additionally, all trademarks, service marks, trade names and trade dress that may appear on our Site or Service are owned by us, our licensors, or both. Except for the limited use rights granted in these Terms of Use, you shall not acquire any right, title or interest in our Site or Service. Any rights not expressly granted in these Terms of Use are expressly reserved.
    </p>

    <h2> ACCESS OR USING OUR SERVICE</h2>
    <p>(a) To access or use some content or features of our Site or Service, you may choose to provide us certain information, we may require you to provide certain information, or require that you establish an account with us through registration. Your access to certain content or features may be limited if you are not 18 years of age or older, or the legal age in your jurisdiction to form a legally binding agreement, or for other reasons.

    (b) Whenever you submit information to us, you agree to provide true, accurate and complete information and agree to, if the option is available, promptly update the information if there have been changes to the information you provided.
    
    (c) Accounts. We may also impose restrictions on your ability to establish an account (e.g., age limits, restrict business entities from setting up accounts, limitations on the number of accounts, etc.). When registering an account, you may need to select a username ("ID") and password. You are responsible for keeping your ID and password, and other account information, confidential and are fully responsible for all activities that occur under your account, whether or not you authorized such activities. You agree to notify us immediately of any breach of security or unauthorized use of your account or ID and password by sending email to us.
    
    (d) Uploading data and using communication services - Some of our features or services may allow you to communicate to or upload data of the other users or entities, such as but not limited to, your employees, clients and potential clients, in any such case you are responsible and liable for obtaining the consent of those users or entities whose data you are uploading or your are communicating with, and you shall defend, indemnify and hold us completely harmless from any consequences of uploading such data or communicating to such users or entities though our services.
    </p>

    <h2>LIMITATION, SUSPENSION OR TERMINATION</h2>
    <p>(a) We may, in our sole discretion, temporarily or permanently change, limit, suspend or terminate your access to our Site or Service (including any Site Content or User Content) without prior notice. We may do so based on changes to our business practices (e.g., eliminating a Service, etc.), if you violate the letter and spirit of these Terms of Use, or for any other lawful reason. You agree that we are not liable to you or any third party for any such action.

    (b) Any limitation, suspension or termination we impose shall not alter your obligations to us under these Terms of Use. The provisions of these Terms of Use which by their nature should survive any such action on our part shall survive including, but not limited to, the rights and licenses that you have granted to us in your User Content and provisions relating to indemnities, releases, disclaimers, limitations on liability, mandatory arbitration, no class action and all of the miscellaneous provisions in this document.
    
    </p>

    <h2>ACCEPTABLE USE</h2>
    <p>(a) Our Site and Service (including, without limitation, Site Content and User Content) are provided for your information and personal, non-commercial use only. When using our Site or Service, you agree to comply with these Terms of Use, and all applicable federal, state and local laws. (b) Except as expressly permitted by these Terms of Use, you may not: - use our Site or Service in an unlawful or fraudulent manner or for such purposes, to collect personally identifiable information, or to impersonate other users;
    </p>

    <p>ZM QRCode will not be liable for any damages of any kind arising from the use of this site, including, but not limited to, direct, indirect, incidental, punitive, and consequential damages.</p>
    <p>- modify our copyright/trademark or other proprietary rights notices, or interfere with the security-related features of our Site or Service (e.g., those that prevent or restrict copying Site Content);</p>
    <p>- use our Site or Service in any way to manipulate or distort, or undermine the integrity and accuracy of, any reviews and ratings, or take any action to interfere with, damage, disrupt any part of our Site or Service;
    </p>
    <p>- use our Site or Service to send, knowingly receive, upload/post, download, any material which does not comply with our content standards;
    </p>
    <p>use our Site or Service to transmit or facilitate the transmission of any unsolicited or unauthorized advertising or promotional material; use our Site or Service to transmit any data, or upload to our Site or Service any data, that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware; decompile, reverse engineer or disassemble any portion of our Site or Service;
    use any robot, spider, other automatic device or manual process to monitor or copy our web pages or the content contained our Site or Service, or use network-monitoring software to determine architecture of or extract usage data from our Site or Service; or engage in any conduct that restricts or inhibits any other user from using or enjoying our Site or Service. (c) You agree to fully cooperate with us to investigate any suspected or actual activity that is in breach of these Terms of Use.</p>

    <h2>USER CONTENT</h2>
    <p>(a) We may now or in the future permit you to post, upload, transmit through or otherwise provide through our Site or Service (collectively, "submit") messages, text, illustrations, files, images, graphics, photos, comments, sounds, music, videos, information (e.g., your name, e-mail address, etc.) and other content (collectively "User Content"). For example, any comment, rating or review of a product or a merchant that you post through our Site or Service is your User Content.

    (b) Standards: You agree not to submit any User Content protected by copyright, trademark, patent, trade secret, moral right, or other intellectual property or proprietary right without the express permission of the owner of the respective right. You are solely liable for any damage resulting from your failure to obtain such permission or from any other harm resulting from your User Content. Furthermore, you represent and warrant that your User Content: - will be, in the context of reviews and ratings, based upon an actual shopping experience with the relevant merchant or third party, accurate (where facts are stated), reflect opinions genuinely held by you, and comply with all applicable laws;
    </p>
    <p>- will be truthful, non-misleading and non-deceptive;
    </p>
    <p>- will not contain any material which is tortious (e.g., defames or invades the privacy of any person, etc.), obscene, offensive, hateful or inflammatory;</p>
    <p>- will not promote sexually explicit material or violence, or promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</p>
    <p>- does not breach any legal duty you owe to a third party, such as a contractual duty or a duty of confidence;</p>
    <p>- will not be threatening, abuse or invade anothers privacy, or provided with an intent to harass, upset or embarrass any other person;</p>
    <p>- will not be provided with an intent to impersonate any person, to misrepresent your identity or affiliation with any person, or to falsely give the impression that your User Content comes from someone else; or</p>
    <p>- will not advocate, promote or assist any unlawful act (e.g., criminal acts, copyright infringement, computer misuse, etc.).</p>
    <p>(c) We may monitor, edit or remove any User Content for violation of the letter or spirit of these terms, or for any other lawful reason. However, we have no obligation to look for, edit or remove (except for removal of content pursuant to Section 15) any User

    Content for any reason including, but not limited to, violation of these terms.
   </p>
   <p>(d) License: We do not claim ownership to your User Content. However, by submitting User Content, you grant us an irrevocable, perpetual, non-exclusive, transferable, royalty-free, worldwide license (sub licensable through multiple tiers) to use, copy, transmit, excerpt,perform, distribute, publish, display, and otherwise exploit such User Content (including, without limitation, your name and likeness, voice, and any other materials contained within your User Content) for any purpose whatsoever in all formats, on or through any media, software, formula, or medium now known or hereafter developed. Additionally, to the extent permitted by applicable law, you waive any moral rights you may have in any User Content you submit.
   </p>
    <h2> Privacy</h2>
    <p>Our collection and use of information about users of our Site and Service is governed by our Privacy Policy, which is incorporated into these Terms of Use by this reference. By accessing or using our Site or Service, you consent to the collection and use of this information, including the transfer of this information to the United States and/or other countries for storage, processing, and use by ZM QRCode Services.</p>
    <h2>DISCLAIMER OF WARRANTIES</h2>
    <p>
    (a) YOU UNDERSTAND AND AGREE THAT: TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR USE OF OUR SITE AND SERVICE IS AT YOUR SOLE RISK. OUR SITE AND SERVICE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
    
    (b) WE MAKE NO WARRANTY THAT (i) OUR SITE OR SERVICE WILL MEET YOUR REQUIREMENTS, (ii) OUR SITE OR SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF OUR SITE OR SERVICE WILL BE ACCURATE OR RELIABLE, OR (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH OUR SITE OR SERVICE WILL MEET YOUR EXPECTATIONS.
    </p>
    <h2>LIMITATION OF LIABILITY</h2>
    <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU EXPRESSLY UNDERSTAND AND AGREE THAT ZM QRCODE SERVICES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF ZM QRCODE SERVICES HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (i) THE USE OR THE INABILITY TO USE OUR SITE OR SERVICE; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION, OR SERVICES PURCHASED OR OBTAINED, OR MESSAGES RECEIVED, OR TRANSACTIONS ENTERED INTO THROUGH OR FROM OUR SITE OR SERVICE; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON OUR SITE OR SERVICE; OR (v) ANY OTHER MATTER RELATING TO OUR SITE OR SERVICE. IN NO EVENT SHALL ZM QRCODE SERVICES. TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING OUR SITE OR SERVICE.
</p>
    <h2>INDEMNIFICATION    </h2>
    <p>You agree to defend, indemnify, and hold harmless ZM QRCode Services, its officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs, or debt, and expenses (including but not limited to attorneys fees) arising from: (i) your use of and access to our Site and Service; (ii) your violation of any term of these Terms of Use; (iii) your violation of any third-party right, including without limitation any copyright, property, or privacy right; or (iv) any claim that your User Content caused damage to a third party. This defense and indemnification obligation will survive these Terms of Use and your use of our Site and Service.
</p>
    <h2>GOVERNING LAW AND ARBITRATION</h2>
    <p>These Terms of Use shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law principles. For any dispute you have with ZM QRCode Services, you agree to first contact us and attempt to resolve the dispute with us informally. In the unlikely event that ZM QRCode Services has not been able to resolve a dispute it has with you after sixty (60) days, we each agree to resolve any claim, dispute, or controversy (excluding any ZM QRCode Services claims for injunctive or other equitable relief) arising out of or in connection with or relating to these Terms of Use by binding arbitration by JAMS.</p>
    </div>
);

export default TermsOfService;
