import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassEnd, faHandshake, faChartLine, faQrcode, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import '../../styles/ADash.css';

const Sidebar = ({ setActiveSection, isOpen, toggleSidebar }) => {
  const [activeSection, setActiveSectionState] = useState('plan-expiry'); // Default active section

  const handleSectionChange = (section) => {
    setActiveSection(section);
    setActiveSectionState(section); // Update active section state
  };

  return (
    <div className={`sidebar1 ${isOpen ? 'open' : ''}`}>
      <span className="sidebar_toggle" onClick={toggleSidebar}>
        <i className={`fa-solid${isOpen ? ' fa-backward' : ' fa-forward'} fa-lg`} style={{color:"#2fc3ad"}}></i>
      </span>
      <ul className="sidebar_list">
        {/* <li className={`sidebar_item ${activeSection === 'plan-expiry' ? 'active' : ''}`} onClick={() => handleSectionChange('plan-expiry')}>
          <a href="#plan-expiry">
            <FontAwesomeIcon icon={faHourglassEnd} />
            <span>Plan Expiry</span>
          </a>
        </li>
        <li className={`sidebar_item ${activeSection === 'payments' ? 'active' : ''}`} onClick={() => handleSectionChange('payments')}>
          <a href="#payments">
            <FontAwesomeIcon icon={faHandshake} />
            <span>Payments</span>
          </a>
        </li>
        <li className={`sidebar_item ${activeSection === 'analytics' ? 'active' : ''}`} onClick={() => handleSectionChange('analytics')}>
          <a href="#analytics">
            <FontAwesomeIcon icon={faChartLine} />
            <span>Analytics</span>
          </a>
        </li> */}
        <li className={`sidebar_item ${activeSection === 'qrcode' ? 'active' : ''}`} onClick={() => handleSectionChange('qrcode')}>
          <a href="#qrcode">
            <FontAwesomeIcon icon={faQrcode} />
            <span>My QR Codes</span>
          </a>
        </li>
        <li className={`sidebar_item ${activeSection === 'profile' ? 'active' : ''}`} onClick={() => handleSectionChange('profile')}>
          <a href="#profile">
            <FontAwesomeIcon icon={faCircleUser} />
            <span>Profile</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
