import React, { useState } from 'react';
import  axiosPrivate  from '../../api/axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import '../../styles/loginPage.css'
import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media(max-width:480px) {
    flex-direction: column;
  }
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 40px;
  width: 400px;
  margin: 2em 0;
  border-radius: 1em;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);

  @media(max-width:480px) {
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: inherit;
  }
`;

const FormTitle = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  color: #1a2a6c;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const FormLabel = styled.label`
  display: block;
  color: #212121;
  margin-bottom: 5px;
`;

const FormInput = styled.input`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const Img = styled.img`
  @media(max-width:480px) {
    display: none;
  }
`;

const RegistrationForm = () => {

  const rooturl = process.env.rooturl
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');

    // Validate fields
    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    } else if (!validateEmail(email)) {
      return setError('Invalid email address');
    } else if (!validateMobile(mobile) || mobile.length !== 10) {
      return setError('Invalid mobile number');
    } else {
      try {
        setLoading(true);

        // Post data to the correct API endpoint
        const response = await axiosPrivate.post('/api/user/create-account', {
          FirstName: firstName,
          LastName: lastName,
          PhoneNumber: mobile,
          EmailId: email,
          RoleName: 'customer', // Assuming RoleName is the role selected
          Password: password
        });

        const res = response?.data;
        console.log(res);
        
        // Navigate to login page
        toast('Registration successful! Please log in.');
        setTimeout(() => {
          navigate('/login');
        }, 3000); // 3-second delay

      } catch (err) {
        const errorMessage = err?.response?.data?.message || err?.message || 'Error fetching server information';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    }

    // Reset form fields
    setFirstName('');
    setLastName('');
    setEmail('');
    setMobile('');
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setError('');
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9-.]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*$/gm;
    return re.test(email);
  };

  const validateMobile = (mobile) => {
    const re = /^[0-9]{10}$/;
    return re.test(mobile);
  };

  return (
    <>
    <FormContainer>
      <Img src='/contact/contact-people.png' alt='people' height={700} />
      <FormWrapper>
        <FormTitle>Registration Form</FormTitle>
        {error && <div style={{ color: 'red', marginBottom: '15px', textAlign: 'center' }}>{error}</div>}
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormLabel>First Name</FormLabel>
            <FormInput
              type="text"
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Last Name</FormLabel>
            <FormInput
              type="text"
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <FormInput
              type="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Mobile</FormLabel>
            <FormInput
              type="tel"
              value={mobile}
              onChange={(event) => setMobile(event.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Password</FormLabel>
            <FormInput
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>Confirm Password</FormLabel>
            <FormInput
              type="password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              required
            />
          </FormGroup>
          <SubmitButton type="submit" disabled={loading}>{loading ? <span className='spinner' /> : 'Register'}</SubmitButton>
        </form>
      </FormWrapper>
    </FormContainer>
       <ToastContainer /> {/* Ensure this is included in the component tree */}
       </>
  );
}

export default RegistrationForm;
