import React, { useState } from "react";
import Modal from "../BASE/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Services from "../BASE/Services";
import "../BASE/Modal.css";
import "../BASE/Base.css";
import MainQR from "../BASE/MainQR";
import axiosPrivate from "../../../api/axios";

function WiFi() {
  const [linkName, setlinkName] = useState("");
  // const [url, setUrl] = useState("https://www.zmqrcode.com/");
  const [qrCodeDataUrl,setQrCodeDataUrl] = useState("");
  const [hide, setHide] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [genURL, setGenURL] = useState("https://www.zmqrcode.com/");
  const [wifiName, setWifiName] = useState("");
  const [pwd, setPwd] = useState("");
  const [nwtype, setNwtype] = useState("None");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(`/^WIFI:T:${nwtype}+;S:${wifiName}+;P:${pwd}+;;$/`);
      await axiosPrivate.post(
        "/api/links/upload-link",
        {
          LinkName: linkName,
          LinkType: "WIFI",
          LinkData: `WIFI:T:${nwtype};S:${wifiName};P:${pwd};;`,
        },
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
        }
      );

      toast.success("successfully uploaded");

      setGenURL(`WIFI:T:${nwtype};S:${wifiName};P:${pwd};;`);
    } catch (error) {
      toast.error("An error occured...");
      console.error("Error uploading files:", error);
    }
  };
  const handleQrCodeUpdate = (dataUrl) => {
    setQrCodeDataUrl(dataUrl);
  };
  const handleHide = () => {
    setHide(!hide);
  };
  return (
    <div className="flex-container">
      <Services />
      <div className="main_containers">
        <MainQR />
        <div className="card card-1">
          <div className="header-container">
            <div className="header-left">
              <h2>QR Code Generator for Links</h2>
            </div>
            <div className="header-right"></div>
          </div>
          <form onSubmit={handleSubmit} className="form-first-container">
            <div className="video_file">
              <label htmlFor="ssid">
                <span>WiFi Name(SSID)</span>
              </label>
              <input
                id="ssid"
                type="text"
                // multiple
                value={wifiName}
                onChange={(e) => setWifiName(e.target.value)}
                required
              />
            </div>

            <div className="input-wrapper">
              <label htmlFor="pwd">
                <span>Wifi password</span>
              </label>
              <input
                id="pwd"
                type={hide ? "password" : "text"}
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                required
              />
              <span onClick={handleHide} className="eye">
                {!hide ? (
                  <i className="fa-regular fa-eye"></i>
                ) : (
                  <i className="fa-solid fa-eye-slash"></i>
                )}
              </span>
            </div>
            <div className="video_file">
              <label htmlFor="type">
                <span>Network Type</span>
              </label>
              <select id="type" onChange={(e) => setNwtype(e.target.value)}>
                <option value="NONE">NONE</option>
                <option value="WEP">WEP</option>
                <option value="WPA">WPA</option>
                <option value="RAW">RAW</option>
              </select>
            </div>
            <div className="video_file">
              <label htmlFor="folder-name">
                <span>Enter QR code Title</span>
              </label>
              <input
                id="folder-name"
                type="text"
                value={linkName}
                onChange={(e) => setlinkName(e.target.value)}
                required
              />
            </div>
            <div className="button-container">
              <button type="submit" className="submit">
                Submit
              </button>
            </div>
            <ToastContainer />
          </form>
        </div>
      </div>
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onQrCodeUpdate={handleQrCodeUpdate}
          geturl={genURL}
        />
    </div>
  );
}

export default WiFi;
