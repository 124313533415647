import React, { useState } from 'react';
import axiosPrivate from '../../api/axios';
// import axios from 'axios';
import '../../styles/contact.css';

const Contact = () => {
    // console.log(axios.axiosPrivate);
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        find: '',
        message: '',
    });
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const isValidEmail = (email) => {
        const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/;
        return emailRegex.test(email);
    }
    
    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!formData.name?.length)
            return setError('Name is required');
        else if (formData.mobile?.length === 0 || parseInt(formData.mobile).toString() !== formData.mobile)
            return setError('Mobile is required');
        else if (!formData.email?.length || !isValidEmail(formData.email))
            return setError('Email is required');
        else if (!formData.find?.length)
            return setError('Please Specify how did you find us');
        else if (!formData.message?.length)
            return setError('Message is required');

        try {
            setLoading(true);
            const response = await axiosPrivate.post('/api/mail/contact-details-to-company', {

                name: formData.name,
                mobile: parseInt(formData.mobile),
                email: formData.email,
                find: formData.find,
                message: formData.message
            });
            const res = response.data.message;
            setSuccess(res);
            setFormData({
                name: '',
                mobile: '',
                email: '',
                find: '',
                message: '',
            });
        } catch (err) {
            const errorMessage = err?.response?.data?.message || err?.message || 'error fetching server information';
            setError(errorMessage);
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div id='contact'>
                <h1 className='contact-heading'>Contact us</h1>
                <div className='contact'>
                    <div className='image'>
                        <div className='contact-zm'>
                            <img src='/contact/contact-zm.png' alt='zm-logo' />
                            <div>
                                <p>ZM QR Code Services</p>
                                <p >Generate a unique QR code for your brand with us...</p>
                            </div>
                        </div>
                        <img src='/contact/contact-people.png' alt='people' height={570} />
                    </div>
                    <form className='form' onSubmit={handleSubmit}>
                        <h2>Send us a message</h2>
                        <input type='text' id='name' placeholder='Name' value={formData.name} onChange={handleChange} />
                        <input type='text' id='mobile' placeholder='Mobile' inputMode='numeric' value={formData.mobile} onChange={handleChange} />
                        <input type='email' id='email' placeholder='Email'  inputMode="email" value={formData.email} onChange={handleChange} />
                        <input type='text' id='find' placeholder='How did you find us' value={formData.find} onChange={handleChange} />
                        <input type='text' id='message' placeholder='Message' value={formData.message} onChange={handleChange} />
                        {error && <div style={{ color: 'red', marginBottom: '15px', textAlign: 'center' }}>{error}</div>}
                        {success && <div style={{ color: '#2cf651', marginBottom: '15px', textAlign: 'center' }}>{success}</div>}
                        <div>
                            <button onClick={handleSubmit} >
                                {loading ? <span className="spinner" /> : 'Send message'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Contact;
