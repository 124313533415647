import React, { useState } from "react";
import axiosPrivate from "../../api/axios";
import "../../styles/loginPage.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation} from 'react-router-dom';

const LoginPage = () => {
  const rooturl = process.env.ROOTURL;
  console.log(rooturl);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [hide, setHide] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();
  const { from, download } = location.state || {};
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    try {
      setLoading(true);
      const response = await axiosPrivate.post("/api/user/login", {
        EmailId: email,
        Password: password,
      });
      // Store the token in localStorage
      const data = response.data;

      localStorage.setItem("accessToken", response.data.token);
      if (from) {
        // Redirect back to the original page
        navigate(from, { state: { download } });
      } else {
        // Redirect to a default page
        navigate("/Dashboard");
      }

       // Navigate to dashboard upon successful login
      window.location.reload(); // Reload the page to update the Nav component

      setEmail("");
      setPassword("");
    } catch (err) {
      const errorMessage =
        err?.response?.data?.message ||
        err?.message ||
        "Error fetching server information";
      setError(errorMessage);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleHide = () => {
    setHide(!hide);
  };

  return (
    <div className="body-login">
      <img
        className="login-people"
        src="/contact/contact-people.png"
        height={700}
        alt="people"
      />
      <div className="login-container">
        <h2>Login</h2>
        {error && (
          <div
            style={{ color: "red", marginBottom: "15px", textAlign: "center" }}
          >
            {error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label htmlFor="password">Password</label>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <input
                type={hide ? "password" : "text"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                style={{ paddingRight: "30px" }} // Adds space for the icon inside the input
              />
              <span
                onClick={handleHide}
                style={{
                  position: "absolute",
                  right: "10px",
                  cursor: "pointer",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                {!hide ? (
                  <i className="fa-regular fa-eye"></i>
                ) : (
                  <i className="fa-solid fa-eye-slash"></i>
                )}
              </span>
            </div>
          </div>
          <button type="submit" disabled={loading}>
            {loading ? <span className="spinner" /> : "Login"}
          </button>
        </form>
        <div className="links">
          <Link to={"/forget"}>Forgot Password?</Link>
          <Link to={"/signup"}>Register</Link>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
