import React, { useEffect, useState, useCallback } from 'react';
import axiosPrivate from '../../api/axios';
import './myqr.css';
import { Button, Modal } from 'react-bootstrap';

const MyComponent = () => {
  const [data, setData] = useState([]);
  const [folderdata, setFolderdata] = useState([]);
  const [error, setError] = useState(null); 
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [openfile, setOpenfile] = useState(false);
  const [fileContent, setFileContent] = useState(null);
  const [show, setShow] = useState(false);
  const [showFile, setShowFile] = useState(false);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback((content) => {
    setModalContent(content);
    setShow(true);
  }, []);


  const handleFile = useCallback(async(item) => {
    setShowFile(true);
    if(item.Category === 'Link') {
        setFileContent(item);
        
    } else if(item.Category === 'Folder') {
      
        try{
          const response =await axiosPrivate.get(`/api/files/get-files/${item.FolderId}`,{
            headers:{
              Authorization: localStorage.getItem('accessToken')
            }
          });
       
          if(response.status === 200){
            if (Array.isArray(response.data) && response.data.length > 0) {
              const data = {...response.data };
              data.Category=item.Category;
              data.LinkData=data.FileName;// Spread the first element into `data`
              setFileContent(data); // Update state with the fetched data
              console.log(typeof(fileContent));
            } 
          }
       
          
        } catch(error) {
          console.error(error);
        }

    }
  
  }, []);
  useEffect(() => {
    if (fileContent) {
      console.log('Updated fileContent:', fileContent);
    }
  }, [fileContent]);
  

  const handleFileClose = useCallback(() => {
    setShowFile(false); 
    setFileContent(null);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/api/user/my-qrcodes', {
            headers: {
              Authorization: localStorage.getItem('accessToken'),
            },
        });
        console.log(response);
        if (response.status === 200) {
          let folder = response.data.Folders;
          let link = response.data.Links;

          let updatedFolder = folder.map(item => ({
            ...item,
            Category: "Folder"
          }));

          let updatedLink = link.map(item => ({
            ...item,
            Category: "Link"
          }));

          let combinedData = [...updatedFolder, ...updatedLink];
          setData(combinedData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError('Error fetching data');
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className='lap'>
        <h1>My Qrs</h1>
        {error ? (
          <p>{error}</p>
        ) : data.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col" className="lap">S.No</th>
                <th scope="col" className="lap">Service</th>
                <th scope="col" className="lap">Name</th>
                <th scope="col" className="lap">Size</th>
                <th scope="col" className="lap">ScanCount</th>
                <th scope="col" className="lap">CreatedAt</th>
                <th scope="col" className="lap">Status</th>
                <th scope="col" className="lap">UpdatedAt</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="lap">{index + 1}</td>
                  <td className="lap">
                    {item.Category === "Folder" ? item.FolderContain : item.LinkType}
                  </td>
                  <td className="lap">
                    <i className="fa-solid fa-folder" style={{color:'#FFBF00'}}  onClick={() => handleFile(item)}></i>
                    {item.Category === "Folder" ? item.FolderName : item.LinkName}
                  </td>
                  <td className="lap">
                    {item.Category === "Folder" ? item.FolderSize : item.LinkSize}
                  </td>
                  <td className="lap">{item.ScanCount}</td>
                  <td className="lap">{new Date(item.createdAt).toLocaleDateString('en-US')}</td>
                  <td className="lap">{item.status}</td>
                  <td className="lap">{new Date(item.updatedAt).toLocaleDateString('en-US')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}
      </div>

      <div className='mobile'>
        <h1>My Qrs</h1>
        {error ? (
          <p>{error}</p>
        ) : data.length > 0 ? (
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">More</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <i className="fa-solid fa-folder" style={{ color: '#FFBF00' }} onClick={() => handleFile(item)}></i>
                    {item.Category === "Folder" ? item.FolderName : item.LinkName}
                  </td>
                  <td>
                    <i onClick={() => handleShow(item)} class="fa-solid fa-circle-info"></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No data available</p>
        )}

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalContent && (
              <>
                <p><strong>Service:</strong> {modalContent.Category === "Folder" ? modalContent.FolderContain : modalContent.LinkType}</p>
                <p><strong>Name:</strong> {modalContent.Category === "Folder" ? modalContent.FolderName : modalContent.LinkName}</p>
                <p><strong>Size:</strong> {modalContent.Category === "Folder" ? modalContent.FolderSize : modalContent.LinkSize}</p>
                <p><strong>ScanCount:</strong> {modalContent.ScanCount}</p>
                <p><strong>CreatedAt:</strong> {new Date(modalContent.createdAt).toLocaleDateString('en-US')}</p>
                <p><strong>UpdatedAt:</strong> {new Date(modalContent.updatedAt).toLocaleDateString('en-US')}</p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
        </Modal>

        <Modal show={showFile} onHide={handleFileClose} centered>
          <Modal.Header closeButton>
            <Modal.Title><i class="fa-solid fa-folder-open" style={{ color: '#FFBF00' }} ></i>Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p>
  {fileContent === null ? (
    <div class="d-flex justify-content-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    </div>
  ) : (
    fileContent.Category === "Folder" ? (
      <>
      <p><strong>File Name(s):</strong></p>
      {Object.keys(fileContent).filter(key => !isNaN(key)).map((key, index) => (
        <div key={index}>
          <p>{fileContent[key].FileName}</p>
        </div>  ))}
    </>
    ) : fileContent.LinkType === 'WIFI' ? (
      <>
        <p><strong>Wifi Name: </strong>{fileContent.LinkData.split(';')[1].split('S:')[1]}</p>
        <p><strong>Password: </strong>{fileContent.LinkData.split(';')[2].split('P:')[1]}</p>
        <p><strong>Network Type: </strong>{fileContent.LinkData.split(';')[0].split('WIFI:T:')[1]}</p>
      </>
    ) : (
      fileContent.LinkData
    )
  )}
</p>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleFileClose}>Close</Button>
                </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default MyComponent;
