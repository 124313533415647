import React from 'react'
import '../../styles/support.css'

const Support = () => {
    return (
        <div className='support'>
            <h1>World-Class, Worldwide Support, Delivered</h1>
            <h2>Have a human to speak with--anywhere, anytime</h2>
            <div className='info'>
                <article>
                    <span>3 mins.</span>
                    <p>First response time</p>
                </article>
                <article>
                    <span>99%</span>
                    <p>Customer satisfaction score</p>
                </article>
                <article>
                    <span>1 day</span>
                    <p>Average resolution time</p>
                </article>
            </div>
            <div className='image'>
                <article>
                    <img src='/support/support-cloud.png' alt='cloud' height={210} />
                    <p>chat support</p>
                </article>
                <article>
                    <img src='/support/support-at.png' alt='at' />
                    <p>Email support</p>
                </article>
                <article>
                    <img src='/support/support-call.png' alt='call' height={170} />
                    <p>Call support</p>
                </article>
            </div>
        </div>
    )
}

export default Support