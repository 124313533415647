import React, { useState } from 'react';
import Sidebar from './Sidebar'; // Adjust the path as needed
import PlanExpirySection from './PlanExpirySection';
import PaymentsSection from './PaymentsSection';
import AnalyticsSection from './AnalyticsSection';
import ProfileSection from './ProfileSection';
import MyQR from './MyQR';

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'plan-expiry':
        return <PlanExpirySection />;
      case 'payments':
        return <PaymentsSection />;
      case 'analytics':
        return <AnalyticsSection />;
      case 'profile':
        return <ProfileSection />;
      case 'qrcode':
        return <MyQR/>;
      default:
        return <div>Welcome! Select an option from the sidebar.</div>;
    }
  };

  return (
    <div className="dashboard">
      <Sidebar setActiveSection={setActiveSection} isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`amain-content ${isSidebarOpen ? 'open' : ''}`} >
        {renderContent()}
      </div>
    </div>
  );
}

export default Dashboard;
