import React,{createContext, useContext,useState, useEffect } from "react";
import { Routes, Route ,Navigate } from "react-router-dom";
import  jwtDecode  from "jwt-decode";
import RegistrationForm from "./components/authentication/registration";
import LoginPage from "./components/authentication/loginPage";
import Nav from "./components/nav";
import NotFound from "./components/notFound";
import Home from "./components/home/home";
import AboutQr from "./components/AboutQr/AboutQr";
import ForgotPassword from "./components/authentication/forgotPassword";
import ResetPassword from "./components/authentication/resetPassword";
import ViewAll from "./components/service/viewall";
import PricingPage, { Industry } from "./components/Pricing/pricing";
import AboutUS from "./components/AboutUS/AboutUS";
import TermsOfService from "./components/terms&policies/Terms";
import DashboardAdmin from "./components/Admin/ADashboard";
import DashboardUser from "./components/User/dashboard";
import URL from "./components/service/URL/url";
import WiFi from "./components/service/WIFI/wifi";
import VideoUploadForm from "./components/service/VIDEO/video";
import ImageUploadForm from "./components/service/IMAGE/image";
import DocumentUploadForm from "./components/service/DOCUMENT/docs";
import AudioUploadForm from "./components/service/AUDIO/audio";
import GoogleFormsUpload from "./components/service/GOOGLEFORMS/googleForms";
import MenuCardUploadForm from "./components/service/MENUCARD/menu";
import PptUploadForm from "./components/service/PPT/ppt";
import ComingSoon from "./components/comingsoon";
import QRLayout from "./components/service/QRGeneration/QRLayout";
import WhatsAppURL from "./components/service/WHATSAPP/whatsapp";
import Text from "./components/service/TEXT/text";
import Location from "./components/service/LOCATION/location";
import Website from "./components/service/WEBSITE/website";
import BusinessCard from "./components/service/BUSINESSCARD/businesscard";
import Coupon from "./components/service/COUPON/coupon";
import UPI from "./components/service/UPI/upi";
import Socialmedia from "./components/service/SOCIALMEDIA/socialmedia";
import Facebook from "./components/service/FACEBOOK/facebook";
import Youtube from "./components/service/YOUTUBE/youtube";
import Zoommeeting from "./components/service/ZOOMMEETING/zoommeeting";
import Googlemeet from "./components/service/GOOGLEMEET/googlemeet";
import Meeting from "./components/service/MEETING/meeting";
import BusinessCard_new from "./components/service/BUSINESSCARD/BusinessCard_new";
import Frame_1 from "./components/Frames/Frame_1";
import Frame_2 from "./components/Frames/Frame_2";
import Video_new from "./components/service/VIDEO/Video_new";
import Dashboard from "./components/User/dashboard";
import Plans from "./components/Pricing/Plans";
import Pricing from "./components/Pricing/PricingPage";
import ShowFiles from "./components/Onscan/ShowFiles";
import Image_new from "./components/service/IMAGE/Image_new";
import Audio_new from "./components/service/AUDIO/Audio_new";
import QRCodeStyling from "qr-code-styling";
import ShowLinkData from "./components/Onscan/ShowLinkData";
import PrivateRoute from './PrivateRoute';  
import RefundPolicy from "./components/RefundPolicy";


const App = () => {
  // const accessToken = localStorage.getItem("accessToken");
  // let decoded, role = "";
  // if (accessToken) {
  //   decoded = jwtDecode(accessToken);

  //   const exp = decoded?.exp;
  //   if (Date.now() <= exp * 1000) {
  //     role = decoded?.userInfo.role || "";
  //   }
  // }
 const role="user";
  return (
    <Routes>    
      <Route path="/" element={<Nav />}>
      <Route path="/refundPolicy" element={<RefundPolicy/>}/>
        {/* {/* public routes */ }
        {/* <Route index={role === "" ? true : false} element={<Home />} /> */}
        <Route index={role === role } element={<Home />} />
        {/* //added new */}
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="pricingpage" element={<Pricing/>}/>
        <Route path="industry" element={<Industry/>}/>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<RegistrationForm />} />
        <Route path="forget" element={<ForgotPassword />} />
        <Route path="reset" element={<ResetPassword />} />
        <Route path="aboutqr" element={<AboutQr />} />
        <Route path="aboutus" element={<AboutUS />} />
        <Route path="businesscard_new" element={<BusinessCard_new />} />
      
        <Route path="video_new" element={<Video_new />} />
         <Route path="ADashboard" element={<DashboardAdmin/>}/>
         <Route path="Dashboard" element={<Dashboard/>}/>
         <Route path="Plan" element={<Plans/>}/>
          {/* <Route path="video_new" element={<Video_new />} /> */}
        
         
         <Route path="/showfiles/:FolderId" element={<ShowFiles/>}/>
         <Route path="/showlinkdata/:LinkId" element={<ShowLinkData/>}/>

        <Route path="services">
          <Route index element={<ViewAll />} />
          <Route path="audios" element=
            { <PrivateRoute>
              <Audio_new />
            </PrivateRoute>}/>
          <Route path="images" element={<Image_new/>}/>
          <Route path="videos" element={<PrivateRoute><Video_new /></PrivateRoute>} />
          <Route path="links" element={<PrivateRoute><URL /></PrivateRoute>} />
          <Route path="location" element={<PrivateRoute><Location /></PrivateRoute>} />
          <Route path="wifi" element={<PrivateRoute><WiFi /></PrivateRoute>} />
    
          {/* <Route element={<QRLayout />}>Fservices
            <Route path="video" element={<VideoUploadForm />} />
            <Route path="image-gallery" element={<ImageUploadForm />} />
            <Route path="audio" element={<AudioUploadForm />} />
            <Route path="docs" element={<DocumentUploadForm />} />
            <Route path="google-forms" element={<GoogleFormsUpload />} />
            <Route path="menu" element={<MenuCardUploadForm />} />
            <Route path="ppt" element={<PptUploadForm />} />    
            <Route path="website" element={<Website />} />
            <Route path="businesscard" element={<BusinessCard />} />
            <Route path="coupon" element={<Coupon />} />
            <Route path="whatsapp" element={<WhatsAppURL />} />
            <Route path="text" element={<Text />} />
            <Route path="upi" element={<UPI />} />
            <Route path="socialmedia" element={<Socialmedia />} />
            <Route path="facebook" element={<Facebook />} />
            <Route path="youtube" element={<Youtube />} />
            <Route path="zoommeeting" element={<Zoommeeting />} />
            <Route path="googlemeet" element={<Googlemeet />} />
            <Route path="meeting" element={<Meeting />} />
          </Route> */}
          <Route path="coming-soon" element={<ComingSoon />} />
        </Route>


        <Route path="pricing" element={<PricingPage />} />
        {/* terms */}
        <Route path="Terms" element={<TermsOfService />} />
        <Route path="PP" element={<TermsOfService />} />
        <Route path="CP" element={<TermsOfService />} />

        {/* role-based dashboards */}
        {role === "admin" && (
          <Route
            index={role === "admin" ? true : false}
            element={<DashboardAdmin />}
          />
        )}
        {role === "user" && (
          <Route
            index={role === "admin" ? true : false}
            element={<DashboardUser />}
          />
        )}

        {/* catch all */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  
  );
};

export default App;
