import React from 'react';
import '../../styles/services.css';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const navigate = useNavigate();
  return (
    <div className="container">
      <h1>Services</h1>
      <p>We specialize in QR code solutions, offering accurate and time-saving services for businesses and personal use, including customized QR code creation for diverse applications.</p>

      <div className="services">
        <div className="service-card">
          <h2>Video</h2>
          <img src="/services/Video.png" alt="Video Service" />
          <p>Keep your special moments alive by sharing your videos and experiencing the laughter, joy, and adventures with your loved ones with our Unique QR Code.</p>
          <button onClick={() => navigate('/services/BUSINESSCARD/businesscard.js')}>Create QR Code</button>
        </div>
        <div className="service-card">
          <h2>Digital Business Cards</h2>
          <img src="/services/digital business cards.png" alt="Digital Business Cards Service" />
          <p>Create professional digital business cards quickly by utilizing pre-designed templates. Effortlessly connect with others, make a lasting impression, and establish valuable relationships.</p>
          <button onClick={() => navigate('/services/coming-soon')}>Create QR Code</button>
        </div>
        <div className="service-card">
          <h2>Images</h2>
          <img src="/services/images.png" alt="Images Service" />
          <p>Turn your valuable photos into a timeless legacy by using a distinctive QR code. Just upload and scan to capture your memories in a single, shareable QR experience.</p>
          <button onClick={() => navigate('/services/coming-soon')}>Create QR Code</button>
        </div>
      </div>

      <button className="view-all-button" onClick={() => navigate('/services')}>View All</button>
      <div>
        <p>Create QR Code in 3 steps</p>
      </div>
      <div className="steps-container">
        <div className="step-card">
          <h2>Step 1</h2>
          <img src="/services/step1.png" alt="Choose the Type" />
          <p>Choose the Type</p>
        </div>
        <div className="step-card">
          <h2>Step 2</h2>
          <img src="/services/step-2 2.png" alt="Generate QR Code" />
          <p>Generate QR Code</p>
        </div>
        <div className="step-card">
          <h2>Step 3</h2>
          <img src="/services/step-3 1.png" alt="Customize & Download" />
          <p>Customize & Download</p>
        </div>
      </div>

      <div className="features-section">
        <h2>Best QR Code Management With Marketing Features</h2>
        <p>Trusted QR Code platform with advanced features</p>
        <div className="features-grid">
          <div className="feature-card">
            <img src="/services/generate unique qr.png" alt="Generate Unique QR Codes" />
            <h3>Generate Unique QR Codes</h3>
            <p>Advanced tools to design and manage QRs</p>
          </div>
          <div className="feature-card">
            <img src="/services/bulk.png" alt="Bulk upload Bulk download" />
            <h3>Bulk Upload and Bulk Download</h3>
            <p>Upload QR's via excel or download QRs in zip and pdf</p>
          </div>
          <div className="feature-card">
            <img src="/services/QR customization.png" alt="QR code customization" />
            <h3>QR Code's Customization</h3>
            <p>Exciting shapes, colors, logo, stickers...</p>
          </div>
          <div className="feature-card">
            <img src="/services/customizable bioQR.png" alt="Customizable Bio Url's" />
            <h3>Customizable Bio Url's</h3>
            <p>Use your short URLs and landing page URLs</p>
          </div>
          <div className="feature-card">
            <img src="/services/advanced analytics.png" alt="Advanced Analytics" />
            <h3>Advanced Analytics</h3>
            <p>Tracking of Location, time, device, browser</p>
          </div>
          <div className="feature-card">
            <img src="/services/beautiful v card.png" alt="Beautiful v Card Plus" />
            <h3>Beautiful v Card Plus</h3>
            <p>Beautiful mesmerizing professional designs</p>
          </div>
          <div className="feature-card">
            <img src="/services/Integrate with google.png" alt="Integrate with Google Analytics" />
            <h3>Integrate with Google Analytics</h3>
            <p>Download analytics in excel or integrate with Google</p>
          </div>
          <div className="feature-card">
            <img src="/services/free account.png" alt="Free Account with Upgrade Options" />
            <h3>Free Account with Upgrade Options</h3>
            <p>Free basic plan; flexible payment options for upgrades</p>
          </div>
        </div>
      </div>


      <div className="features-section">
        <h2>Create a QR Code With Advanced Features</h2>
        <p>Our QR Code software is both highly functional and customizable making it user-friendly for all.</p>
        <div className="features-grid">
          <div className="feature-card">
            <img src="/services/safe.png" alt="Safe" />
            <h3>Safe</h3>
            <p>Your security is our priority. Our QR codes are encrypted with advanced security measures to protect your data at every scan</p>
          </div>
          <div className="feature-card">
            <img src="/services/fast.png" alt="Fast" />
            <h3>Fast</h3>
            <p>Instant access at your fingertips. Our QR codes ensure lightning-fast scanning for a seamless user experience</p>
          </div>
          <div className="feature-card">
            <img src="/services/flexible.png" alt="Flexible" />
            <h3>Flexible</h3>
            <p>Adaptability meets innovation. Our QR codes accommodate various applications, meeting your dynamic needs</p>
          </div>
          <div className="feature-card">
            <img src="/services/Customizable.png" alt="Customizable" />
            <h3>Customizable</h3>
            <p>Make your mark with our customizable QR codes, designed to reflect your brand's unique identity and message</p>
          </div>
          <div className="feature-card">
            <img src="/services/Reliable.png" alt="Reliable" />
            <h3>Reliable</h3>
            <p>Trust in every scan. Our QR codes are reliable, ensuring consistent performance and accessibility whenever you need it.</p>
          </div>
          <div className="feature-card">
            <img src="/services/unlimited creation.png" alt="Unlimited Creation" />
            <h3>Unlimited Creation</h3>
            <p>Create countless QR codes with unlimited customisations and downloads.</p>
          </div>
          <div className="feature-card">
            <img src="/services/Scalable.png" alt="Scalable" />
            <h3>Scalable</h3>
            <p>Grow without limits. Our QR codes are designed to scale with your business, supporting you from startup to enterprise</p>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Services;