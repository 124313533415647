import React, { useState } from 'react';
import  axiosPrivate  from '../../api/axios';
import '../../styles/loginPage.css';
import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useLocation } from 'react-router-dom';

const ForgotPassword = () => {
  const rooturl = process.env.rooturl
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');
    try {
      setLoading(true);
      const response = await axiosPrivate.post('/api/user/forgot-password', { EmailId: email });
      const res = response?.data;
      setSuccess(res.success);
      localStorage.setItem('forgetToken', res.token);

      console.log(success);
      toast('Email was sent to rest your passward!!!.');
      setTimeout(() => {
        navigate('/login');
      }, 3000); // 3-second delay
      setEmail('');

    
  

    } catch (err) {
      const errorMessage = err?.response?.data?.message || err?.message || 'error fetching server information';
      setError(errorMessage);
      console.log(err)
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <div className="body-login">
      <img className='login-people' src='/contact/contact-people.png' height={700} alt='people' />
      <div className='login-container'>
        <h2>Forgot Password</h2>
        {error && <div style={{ color: 'red', marginBottom: '15px', textAlign: 'center' }}>{error}</div>}
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor='f-email'>Email</label>
            <input
              type="email"
              id='f-email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button type="submit">
            {loading ? <span className="spinner" /> : 'Submit'}
          </button>
        </form>
      </div>
      <ToastContainer /> 
    </div>
  );
};

export default ForgotPassword;
