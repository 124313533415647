import React, { useState ,useEffect} from 'react';
import axios from 'axios';
import '../../styles/ADash.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faHourglassEnd, faHandshake, faChartLine, faGear, faCircleUser } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const[active,setActive]=useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname:'',
    mobile:'',
    email: '',
  });

   
  const postData = () => {
    fetch('http://localhost:3500/api/user/get-details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: localStorage.getItem('accessToken')}),
    })
      .then((response) => response.json())
      .then((result) => {
        // Access the nested data object
        const userData = result.data;
        setUserDetails({
          firstname: userData.FirstName,
          lastname: userData.LastName,
          mobile: userData.PhoneNumber,
          email:userData.EmailId
 
        });
      })
      .catch((error) => setError(error));
  };
 console.log(userDetails);
  const isActive=()=>{
    setActive(!active)
  }

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'users':
        return <div>Users Content</div>;
      case 'plan-expiry':
        return <div>Plan Expiry Content</div>;
      case 'payments':
        return <div>Payments Content</div>;
      case 'analytics':
        return <div>Analytics Content</div>;
      case 'settings':
        return <div>Settings Content</div>;
      case 'profile':
        return (
          <div class="edit_container">
            <div class='form_header'>
            <h2>Edit Profile</h2>
            <span><i class="fa-solid fa-toggle-on"></i></span>
            </div>
            <form className="edit-profile-form">
              <div className="form_item">
                <label htmlFor="firstname" className="form_label">Firstname</label>
                <input type="text" className="form_control" id="firstname" value={userDetails.firstname} placeholder="Enter your username" />
              </div>
              <div className="form_item">
                <label htmlFor="lastname" className="form_label">Lastname</label>
                <input type="text" className="form_control" id="lastname" value={userDetails.lastname} placeholder="Enter your username" />
              </div>
              <div className="form_item">
                <label htmlFor="mobile" className="form_label">Mobile</label>
                <input type="text" className="form_control" id="mobile"  value={userDetails.mobile} placeholder="Mobile" />
              </div>
              <div className="form_item">
                <label htmlFor="email1" className="form_label">Email</label>
                <input type="text" className="form_control" id="email1" value={userDetails.email} placeholder="Enter your email" />
              </div>
              <div className="form_item">
                <label htmlFor="password" className="form_label">Current Password</label>
                <input type="password" className="form_control" id="password" placeholder="current password" />
              </div>
              <div className="form_item">
                <label htmlFor="confirm-password" className="form_label">New Password</label>
                <input type="password" className="form_control" id="confirm-password" placeholder="New password" />
              </div>
              <button type="submit" className="btn-primary">Save Changes</button>
            </form>
          </div>
        );
      default:
        return <div>Welcome! Select an option from the sidebar.</div>;
    }
  };

  return (
    <div className="app-container" style={{ display: 'flex' }}>
    <div 
      className={`sidebar1 ${isOpen ? 'open' : ''}`} 
      onMouseEnter={toggleSidebar} 
      onMouseLeave={toggleSidebar}
    >
      <ul className="sidebar_list">
        <li className="sidebar_item" onClick={() => setActiveSection('users')}>
          <a href="#users">
            <FontAwesomeIcon icon={faUsers} />
            <span className={`active ${isOpen ? 'open' : ''}`}>Users</span>
          </a>
        </li>
        <li className="sidebar_item" onClick={() => setActiveSection('plan-expiry')}>
          <a href="#plan-expiry">
            <FontAwesomeIcon icon={faHourglassEnd} />
            <span className={`active ${isOpen ? 'open' : ''}`}>Plan Expiry</span>
          </a>
        </li>
        <li className="sidebar_item" onClick={() => setActiveSection('payments')}>
          <a href="#payments">
            <FontAwesomeIcon icon={faHandshake} />
            <span className={`active ${isOpen ? 'open' : ''}`}>Payments</span>
          </a>
        </li>
        <li className="sidebar_item" onClick={() => setActiveSection('analytics')}>
          <a href="#analytics">
            <FontAwesomeIcon icon={faChartLine} />
            <span className={`active ${isOpen ? 'open' : ''}`}>Analytics</span>
          </a>
        </li>
        <li className="sidebar_item" onClick={() => setActiveSection('settings')}>
          <a href="#settings">
            <FontAwesomeIcon icon={faGear} />
            <span className={`active ${isOpen ? 'open' : ''}`}>Settings</span>
          </a>
        </li>
        <li className="sidebar_item profile" onClick={() => setActiveSection('profile')}>
          <a href="#profile" onClick={postData}>
            <FontAwesomeIcon icon={faCircleUser} />
            <span className={`active ${isOpen ? 'open' : ''}`}>Profile</span>
          </a>
        </li>
      </ul>
    </div>

    <div className="main-content" style={{ marginLeft: isOpen ? '200px' : '60px',padding: '20px', width: `calc(100% - ${isOpen ? '300px' : '60px'})` }}>
      {renderContent()}
    </div>
  </div>
    
  );
}

const UsersSection = () => <div id="users" className="adsection"><h2>Users Section</h2></div>;
const PlanExpirySection = () => <div id="plan-expiry" className="adsection"><h2>Plan Expiry Section</h2></div>;
const PaymentsSection = () => <div id="payments" className="adsection"><h2>Payments Section</h2></div>;
const AnalyticsSection = () => <div id="analytics" className="adsection"><h2>Analytics Section</h2></div>;
const SettingsSection = () => <div id="settings" className="adsection"><h2>Settings Section</h2></div>;

const DashboardAdmin = () => {
  const [activeSection, setActiveSection] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="dashboard">
      <Sidebar setActiveSection={setActiveSection} isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`amain-content ${isSidebarOpen ? 'open' : ''}`}>
        {activeSection === 'users' && <UsersSection />}
        {activeSection === 'plan-expiry' && <PlanExpirySection />}
        {activeSection === 'payments' && <PaymentsSection />}
        {activeSection === 'analytics' && <AnalyticsSection />}
        {activeSection === 'settings' && <SettingsSection />}
      </div>
    </div>
  );
}




export default DashboardAdmin;
