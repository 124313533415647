import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";

const MainQR=()=>{
    const qrCodeRef = useRef(null);
    const [fileExt, setFileExt] = useState("png");
     
  const [url, setUrl] = useState("https://qr-code-styling.com");
 
  const [customSettings, setCustomSettings] = useState(null);

  const [qrCode, setQrCode] = useState(null);
  const [qrCodeDataUrl, setQrCodeDataUrl] = useState("");

    useEffect(() => {
        // Default QR code configuration
        const defaultQrCodeInstance = new QRCodeStyling({
          width: 400,
          height: 400,
          data: url,
          image: "",
          color:'black',
          dotsOptions: {
            type: "extra-rounded",
          },
          cornersSquareOptions: {
            type: "rounded",
       
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 0,
          },
        });
    
        // Initialize QR code instance with default settings
        if (qrCodeRef.current) {
          defaultQrCodeInstance.append(qrCodeRef.current);
        }
    
        // Set default qrCode instance
        setQrCode(defaultQrCodeInstance);
    
        // Clean up QR code on component unmount
        return () => {
          if (qrCodeRef.current) {
            qrCodeRef.current.innerHTML = "";
          }
        };
      }, [url]);
    
      useEffect(() => {
        if (qrCode && qrCodeRef.current) {
          qrCode.update({ data: url, ...customSettings });
        }
      }, [url, qrCode, customSettings]);
      const onDownloadClick = () => {
        if (qrCode) {
          qrCode.download({ extension: fileExt });
        }
      };
      const onExtensionChange = (event) => {
        setFileExt(event.target.value);
      };
 
    return (
        <div className="card card-2">
               
          <div className='card-header'  style={{marginLeft:'auto',marginRight:'auto'}}>
          <h2>ZM QR Code</h2>
       
        </div>
        <img src="/Logos/qrmain.png"></img>
            {/* {qrCodeDataUrl ? (
          <div>
            <h3>Updated QR Code:</h3>
            <img src={qrCodeDataUrl} alt="QR Code" />
          </div>
        ) : (
          <div ref={qrCodeRef}></div>
        )} */}
            <div className="downloadsec">
              <select onChange={onExtensionChange} value={fileExt}>
                <option value="png">PNG</option>
                <option value="jpeg">JPEG</option>
                <option value="webp">WEBP</option>
              </select>
              <button className="download" onClick={onDownloadClick}>
                Download <i className="fa-solid fa-download"></i>
              </button>
            </div>
          </div>
    )
}
export default MainQR;