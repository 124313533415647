import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import './ShowFiles.css'; // Ensure you have this CSS file for styling
import axios from "axios";
import axiosPrivate from "../../api/axios";


const ShowFiles = ()=> {
    const {FolderId} = useParams();
    const [folder,setFolder] = useState(null);
    const [files,setFiles] = useState(null);
    useEffect(()=>{
        const fetchData = async ()=>{
            try{
                const response = await axios.axiosPrivate.get(`/api/qrcc/get-files/${FolderId}`);
                console.log(response);
            }catch(error){
                console.log(error.message);
            }
        };
        fetchData();
    },[FolderId]);
    return ( <></> );
}

export default ShowFiles;