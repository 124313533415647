import React, { useState, useEffect } from "react";
import "../styles/nav.css";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Footer from "./home/footer";
import { jwtDecode } from "jwt-decode";
import { axiosPrivate } from "../api/axios";

const Nav = () => {
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [signed, setSigned] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    if (toggle === true) setToggle(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    const checkAuth = () => {
      const accessToken = localStorage.getItem("accessToken");
      if (accessToken) {
        try {

          setSigned(true);

        } catch (err) {
          setSigned(false);
          localStorage.removeItem("accessToken");
        }
      } else {
        setSigned(false);
      }
    };
    console.log(signed);
    checkAuth();
  }, []);

  const logout = async () => {
    try {
      localStorage.removeItem("accessToken");
      setSigned(false);
      navigate("/login");
    } catch (err) {
      console.log("failed", err?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="nav" id="nav">
        <div className="toggle-logo">
          <button
            onClick={() => setToggle(!toggle)}
            className="nav-toggle"
            id="nav-toggle"
          >
            &#9776;
          </button>
          <div className="logo" onClick={() => navigate("/")}>
            <img src="/nav/nav-zm.png" height={80} alt="zm-logo" />
            <div>
              <p className="zm-name">zmqrcode.com</p>
              <p>Your Security is Our Priority</p>
            </div>
          </div>
        </div>

        <ul className={`nav-items ${toggle ? "active" : ""}`}>
          <li>
            <HashLink smooth to="/#header" onClick={() => setToggle(false)}>
              <i class="fa-solid fa-house"></i>Home
            </HashLink>
          </li>
          <li>
            <Link to="/aboutqr" onClick={() => setToggle(false)}>
              <i class="fa-solid fa-qrcode"></i>About QR
            </Link>
          </li>
          <li>
            <Link to="/aboutus" onClick={() => setToggle(false)}>
              <i class="fa-solid fa-address-card"></i>About ZM
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={() => setToggle(false)}>
              <i class="fa-brands fa-servicestack"></i>Services
            </Link>
          </li>
          <li>
            <Link to="/pricing" onClick={() => setToggle(false)}>
              <i class="fa-solid fa-money-check-dollar"></i>Pricing
            </Link>
          </li>
          {signed ? (
            <>
              <li class="ListItem">
                <Link to='/dashboard'><i class="fa-solid fa-gauge"></i>MyDashboard</Link>
              </li>
              <li className="auth" onClick={() => { setToggle(false); logout(); }}>
                <Link>Logout</Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <HashLink smooth to="/#faq" onClick={() => setToggle(false)}>
                  <i class="fa-solid fa-person-circle-question"></i>FAQ
                </HashLink>
              </li>
              <li>
                <HashLink smooth to="/#contact" onClick={() => setToggle(false)}>
                  <i class="fa-solid fa-message"></i>Contact us
                </HashLink>
              </li>
              <li className="auth">
                <Link to="/login" onClick={() => setToggle(false)}>
                  <i class="fa-solid fa-right-to-bracket"></i> Login
                </Link>
              </li>
              <li className="refund">
                <Link to="/refundPolicy" onClick={() => setToggle(false)}>
                  <i class="fa-solid fa-receipt"></i> Refund Policy
                </Link>
              </li>

            </>
          )}
        </ul>
      </div>
      <div style={{ minHeight: "20vh" }}>
        {/* <div>  */}
        <Outlet />
      </div>
      <Footer />
    </>
  );
};

export default Nav;
