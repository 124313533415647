import React, { useState } from "react";
import Modal from "../BASE/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Services from "../BASE/Services";
import MainQR from "../BASE/MainQR";
import axiosPrivate from "../../../api/axios";
import "../BASE/Modal.css";
import "../BASE/Base.css";
import { FRONTEND_BASE_URL } from '../../../config';
function Image_new() {
  const [folderName, setFolderName] = useState("");
  const [accessType, setAccessType] = useState("Public");
  const [password, setPassword] = useState("");
  const [videoFiles, setVideoFiles] = useState(null);
  const [qrCodeDataUrl,setQrCodeDataUrl] = useState("");
  const handleFileChange = (e) => {
    setVideoFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("hi");
    const formData = new FormData();
    formData.append("FolderName", folderName);
    formData.append("AccessType", accessType);

    if (accessType === "Protected") {
      formData.append("AccessPassword", password);
    }
    if (videoFiles) {
      Array.from(videoFiles).forEach((file) => {
        formData.append("files", file);
      });
    }
    try {
      const response = await axiosPrivate.post(
        "/api/user/upload-files",
        formData,
        {
          headers: {
            Authorization: localStorage.getItem("accessToken"),
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("successfully uploaded");
      setGenURL(
        `${FRONTEND_BASE_URL}/showfiles/${response.data._id}`
      );
    } catch (error) {
      toast.error("An error occured...");
      console.error("Error uploading files:", error);
    }
  };
  const [modalOpen, setModalOpen] = useState(true);
  const [genURL, setGenURL] = useState("https://www.zmqrcode.com/");
  const handleQrCodeUpdate = (dataUrl) => {
    setQrCodeDataUrl(dataUrl);
  };
  return (
    <div className="flex-container">
      <Services />
      <div className="main_containers">
        <MainQR />

        <div className="card card-1">
          <div className="header-container">
            <div className="header-left">
              <h2>QR Code Generator for Audio</h2>
            </div>
            
          </div>
          <form onSubmit={handleSubmit} className="form-first-container">
            <div className="video_file">
              <label htmlFor="video-upload">
                <span>Upload Audiofiles</span>
              </label>
              <input
                id="video-upload"
                type="file"
                accept="Audio/*"
                multiple
                onChange={handleFileChange}
                required
              />
            </div>
            <div className="video_file">
              <label htmlFor="folder-name">
                <span>Enter QR code Title</span>
              </label>
              <input
                id="folder-name"
                type="text"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                required
              />
            </div>
            <div className="video_radio">
              <label>Access Type</label>
              <div className="radio">
                <div style={{ display: "flex" }}>
                  <input
                    id="access-public"
                    type="radio"
                    name="accessType"
                    value="Public"
                    checked={accessType === "Public"}
                    onChange={(e) => setAccessType(e.target.value)}
                  />
                  <label htmlFor="access-public">Public</label>
                </div>
                <div style={{ display: "flex" }}>
                  <input
                    id="access-private"
                    type="radio"
                    name="accessType"
                    value="Private"
                    checked={accessType === "Private"}
                    onChange={(e) => setAccessType(e.target.value)}
                  />
                  <label htmlFor="access-private">Private</label>
                </div>
                <div style={{ display: "flex" }}>
                  <input
                    id="access-protected"
                    type="radio"
                    name="accessType"
                    value="Protected"
                    checked={accessType === "Protected"}
                    onChange={(e) => setAccessType(e.target.value)}
                  />
                  <label htmlFor="access-protected">Protected</label>
                </div>
              </div>
            </div>
            {accessType === "Protected" && (
              <div>
                <label htmlFor="password">Password:</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            )}
            <div className="button-container">
              <button type="submit" className="submit">
                Submit
              </button>
            </div>
            <ToastContainer />
          </form>
          
        </div>
      </div>
   
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onQrCodeUpdate={handleQrCodeUpdate}
          geturl={genURL}
        /> 
    </div>
  );
}

export default Image_new;
