import React,{useState,useEffect} from 'react';
import '../../styles/AboutQrPage.css';

const FAQSection = () => {
  const [accounts, setAccounts] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState('');

  useEffect(() => {
      setAccounts([
          { qn: "What is a QR code?", ans: " A QR code (Quick Response code) is a type of matrix barcode (or two-dimensional barcode) that can be read by smartphones and QR code readers to quickly link to a website, display text, or perform other functions." },
          { qn: "How does a QR code work?", ans: "A QR code stores data in a series of black and white squares. When scanned, the pattern is decoded to reveal the stored information, such as a URL or contact details." },
          { qn: "What are the common uses of QR codes?", ans: "QR codes are commonly used for marketing (linking to websites), providing product information, mobile payments, event ticketing, and quickly sharing contact information." },
          { qn: "Can a QR code be customized?", ans: "Yes, QR codes can be customized in terms of color, design, and incorporating logos, as long as the basic pattern remains scanna" },
          { qn: "Are QR codes secure", ans: "QR codes themselves are not secure or insecure; it depends on the information they contain. Users should be cautious when scanning QR codes from unknown sources." },
          { qn: "What data can be stored in a QR code?", ans: "A QR code can store various types of data, including URLs, plain text, email addresses, phone numbers, and vCards (digital business cards)." },
          { qn: "How much data can a QR code store?", ans: "The maximum capacity of a QR code is up to 7,089 characters for numeric data and up to 2,953 characters for alphanumeric data." },
          { qn: "What happens if a part of the QR code is damaged?", ans: "QR codes have built-in error correction that allows them to still be read even if up to 30% of the code is damaged." },
          { qn: "What is the difference between a static and a dynamic QR code?", ans: "A static QR code contains fixed information that cannot be changed once created, whereas a dynamic QR code can have its destination URL changed without altering the QR code itself." },
          { qn: "How do you generate a QR code?", ans: "QR codes can be generated using various online QR code generators or software libraries in programming languages like JavaScript, Python, and others" },
          { qn: "What software can scan QR codes?", ans: "Most modern smartphones have built-in QR code scanning capabilities in their camera apps. There are also numerous third-party apps available for QR code scanning." },
          { qn: "Can QR codes be scanned from a screen?", ans: "Yes, QR codes can be scanned from any surface, including screens and printed materials." },
          { qn: "How are QR codes different from barcodes?", ans: "QR codes can store more information and can be scanned from any angle, unlike traditional barcodes that are one-dimensional and require horizontal scanning." },
          { qn: "Can QR codes expire?", ans: "Static QR codes do not expire, but the content they link to can change or become unavailable. Dynamic QR codes can be set to expire or have their destinations updated." }



          
      ])
  }, []);  const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
};

return (
    <div id='faq'>
        <h2>Frequently Asked Questions</h2>
        <div className="faq-items">
            {accounts.map((item, index) => (
                <div className="faq-item" key={index}>
                    <div
                        className={`faq-question ${activeQuestion === index ? 'active' : ''}`}
                        onClick={() => toggleQuestion(index)}
                    >
                        {item.qn}
                    </div>
                    {activeQuestion === index && (
                        <div className="faq-answer">
                            {item.ans}
                        </div>
                    )}
                </div>
            ))}
        </div>
    </div>
);


};

const AboutQr = () => {
  return (
    <div className="about-container">
      <div className="header-about">
        <h1>All About QR Codes: Everything You Need To Know</h1>
      </div>
      
      <div className="section">
        <h2>1. Introduction to QR Codes</h2>
        <div className="content">
          <div className="text">
            <p>As technology evolves, our interactions with it also evolve. One advancement that has become immensely popular is the QR code. These simple black and white squares carry a plenty of information and possibilities. Originating in Japan and now widely used across various industries, QR codes have made significant progress. This comprehensive guide explores the history, functionality, and diverse applications of QR codes in today's digital landscape. Whether you're a business owner seeking operational efficiency or a consumer intrigued by this technology, this guide provides all the essential information about QR codes.</p>
          </div>
          <div className="image">
            <img src="/About Us/Page1.png" height={350} width={350} alt="QR Code Introduction" />
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>2. History and Evolution of QR Codes</h2>
        <div className="content">
          <div className="text">
            <p>QR codes were invented in 1994 by a Japanese automotive company named Denso Wave, a subsidiary of Toyota Motors. The initial design was influenced by the black and white counters played on a Go board. The purpose of the QR code system was to facilitate tracking of the types and numbers of automobile parts. Over time, the use of QR codes expanded beyond the automotive industry, and they are now used in a wide range of industries worldwide.</p>
          </div>
          <div className="image">
            <img src="/About Us/Page2.png" height={350} width={350} alt="History of QR Codes" />
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>3. How Do QR Codes Work?</h2>
        <div className="content">
          <div className="text">
            <p>QR codes, short for Quick Response codes, function as two-dimensional barcodes that store information. These codes consist of black squares arranged on a white background, encoding data such as URLs, text, or contact information. When scanned by a QR code reader or smartphone camera, the encoded information is instantly accessed, enabling quick and convenient interactions.</p>
            <p>Understanding the operational principles of QR codes is essential for their effective integration in different sectors. By exploring the intricacies of QR code functionality, both individuals and businesses can utilize this technology to streamline operations, improve customer interaction, and foster innovation in their domains. Stay tuned for a detailed exploration of the operational intricacies that make these versatile digital tools highly beneficial.</p>
          </div>
          <div className="image">
            <img src="/About Us/Page3.png" height={350} width={350} alt="How QR Codes Work" />
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>4. Different Types of QR Codes</h2>
        <div className="content">
          <div className="text">
            <p>While QR codes are commonly used to store URLs and contact information, there are several other types that cater to specific needs. These include dynamic QR codes that allow for editing the encoded data without changing the QR code itself, as well as static QR codes that permanently store the information once generated. Additionally, there are custom-designed QR codes, such as those with logos or unique shapes, which can be tailored to enhance branding efforts. Understanding the different types of QR codes available can help you choose the most suitable option for your intended purpose. Let's explore these variations further to maximize the potential of QR codes in your endeavors.</p>
          </div>
        </div>
        <div className="image">
          <img src="/About Us/Page4.png" alt="Types of QR Codes" className='image-about' />
        </div>
      </div>
      
      <div className="section">
        <h2>5. Advantages of Using QR Codes</h2>
        <div className="content">
          <div className="text">
            <p>There are several types of QR codes, each differing in storage capacity, size, and error correction level. Here are some of them:</p>
            <ul>
              <li>QR code Model1 and Model2: These are the most common types of QR codes. Model 1 is the original QR code and can store up to 1,167 numerals. Model 2, an improvement on Model 1, can store up to 7,089 numerals. It can be read smoothly even if it is distorted in some way.</li>
              <li>Micro QR code: This is a smaller version of the original QR code, designed for situations where space is limited. It can encode kanji, 8-bit graphic character sets, alphanumeric characters, and other special characters.</li>
              <li>rMQR code: The rectangular micro QR code (rMQR code) is a rectangular version of the micro QR code. It can store 219 alphanumeric characters, 361 numeric, and 92 kanji characters.</li>
              <li>SQRC: SQRC (Secret-function-equipped QR Code) is a QR code that has data reading restrictions and can be used for managing private information. It can be read with a specific scanner.</li>
              <li>Frame QR code: A Frame QR code is a QR code with a “canvas area” that can be flexibly used. Graphics, letters, and more can be flexibly arranged in the canvas area, making it possible to lay out the code without losing the design of illustrations, photos, etc.</li>
            </ul>
            <p>In addition to these, there are more than 30 different types of QR codes that can be created for business or personal use:</p>
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>6. Common Uses of QR Codes</h2>
        <div className="content">
          <div className="text">
            <p>QR codes have become versatile tools in various industries. One common application is in product packaging, where they provide quick access to product information, reviews, and promotions. In the restaurant sector, QR codes on menus streamline ordering processes and offer nutritional details. Real estate agents utilize QR codes on property signboards for virtual tours and additional property details. Event organizers use QR codes for ticketing and seamless check-ins. Additionally, educational institutions incorporate QR codes in textbooks for supplementary learning materials. Understanding these common uses can inspire innovative ways to integrate QR codes into your business or personal endeavors effectively. Keep exploring to unlock more QR code potentials.</p>
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>7. Best Practices for Creating Effective QR Codes</h2>
        <div className="content">
          <div className="text">
            <p>To ensure the success of your QR code campaigns, follow these best practices:</p>
            <ul>
              <li>Provide clear instructions: Include a call-to-action that guides users on what to do once they scan the QR code.</li>
              <li>Test and optimize: Always test your QR codes on different devices and in various environments to ensure they work effectively.</li>
              <li>Direct to valuable content: Link your QR codes to relevant and engaging content to provide users with a rewarding experience.</li>
              <li>Consider the size and placement: Ensure your QR codes are large enough to scan easily and are placed in visible locations.</li>
              <li>Monitor performance: Track metrics and analytics to measure the success of your QR code campaigns and make necessary adjustments.</li>
            </ul>
            <p>By following these best practices, you can maximize the impact of your QR code marketing efforts. Stay tuned for more tips on leveraging QR codes effectively.</p>
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>8. Security Concerns and Risks Associated with QR Codes</h2>
        <div className="content">
          <div className="text">
            <p>QR codes are effective for marketing, it's crucial to consider security issues. They can be manipulated to direct users to harmful sites or fraudulent activities. To reduce risks, only scan QR codes from reliable sources and steer clear of those in questionable places. Apply security features like password protection or encryption for sensitive QR code data. Educate your audience on safe scanning practices to prevent threats. Security is key to upholding trust and credibility in QR code campaigns. Look out for our upcoming posts for useful tips on boosting QR code security.</p>
          </div>
          <div className="image">
            <img src="/About Us/Page5.png" height={350} width={350} alt="QR Code Security" />
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>9. Future Trends in QR Code Technology</h2>
        <div className="content">
          <div className="text">
            <p>As QR codes continue to evolve, future trends in technology are shaping their capabilities. One exciting trend is the integration of augmented reality (AR) with QR codes, creating more immersive and interactive experiences for users. Additionally, advancements in QR code customization allow businesses to incorporate branding elements seamlessly. The emergence of dynamic QR codes, which can be edited post-creation, opens up endless possibilities for real-time updates and tracking. Stay ahead of the curve by exploring these trends to leverage QR codes effectively in your marketing strategies.</p>
          </div>
          <div className="image">
            <img src="/About Us/Page6.png" height={350} width={350} alt="Future of QR Codes" />
          </div>
        </div>
      </div>
      
      <div className="section">
        <h2>10. Conclusion: The Power of QR Codes in the Digital Age</h2>
        <div className="content">
          <div className="text">
            <p>QR codes are not just a trend, they’re a really important tool in today’s digital world. They’ve changed a lot because of new technology like AR, and because they can be customized and have lots of different uses. This has changed how businesses talk to their customers. By keeping up with new tech and creative uses of QR codes, you can use them to their full potential in your marketing plans. As technology keeps getting better, QR codes will become even more useful and important for connecting offline and online experiences. Use QR codes to improve user engagement, track analytics in real-time, and stay competitive in the digital world.</p>
          </div>
          <div className="image">
          </div>
        </div>
      </div>

      <div className='section'>
        <FAQSection />
      </div>
    </div>
  );
};

export default AboutQr;
