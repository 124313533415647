import React from 'react';
import '../../styles/AboutUs.css';

const AboutUs = () => {
  return (
    <section className="about-us">
      <h2>About Us</h2>
      <div className="about-us-content">
        <div className="about-us-image">
          <img src="About Us/about1.jpg" height={350} width={400} alt="QR code solutions" />
        </div>
        <div className="about-us-text">
          <p>We offer Advanced QR code Solutions for your businesses, including creating unique QR codes, providing analytics, and integrating videos and images. Our services also cover digital business cards, event ticketing, and social media integration for better connections with audiences, aiming to streamline operations and marketing strategies effectively.</p>
        </div>
      </div>
      <div className="about-us-section ">
        <div className="about-us-content-box">
          <h3>Vision </h3>
          <p>To be the leading provider of innovative QR code solutions that empower businesses to connect with their customers in a dynamic, efficient, and secure manner.</p>
        </div>
      </div>
      <div className="about-us-section ">
        <div className="about-us-content-box">
          <h3>Mission </h3>
          <p>To offer a comprehensive suite of QR code services that enhance user engagement, streamline operations, and provide actionable insights through advanced analytics.</p>
        </div>
      </div>
      <div className="about-us-section ">
        <div className="goals-content">
          <div className="goals-text">
            <h3>Goals </h3>

            <p>To continuously innovate and provide customized QR code designs that cater to the unique needs of each business.</p>
            <p>To maintain a user-friendly platform that simplifies the creation and management of QR codes for businesses of all sizes.</p>
            <p>To ensure customer satisfaction through reliable support and versatile services that cover various aspects of digital marketing and operations.</p>

          </div>
          <div className="goals-image">
            <img src="/About Us/bro.png" height={300} width={350} alt="QR code creation" />
          </div>
        </div>
      </div>
      <div className="about-us-section">
        <h3>Why ZM-QR Code?</h3>
        <p>
          Choosing ZM QR Code Services can bring numerous advantages:
        </p>
        <ul>
          <li>Innovation and Customization: We offer distinctive QR shapes and stickers, enhancing your business's visibility and attracting more customer engagement.</li>
          <li>Comprehensive Analytics: Benefit from real-time advanced analytics to monitor the effectiveness of your QR code campaigns and guide data-based decisions.</li>
          <li>Versatility: From digital business cards to event ticketing, our services cover a wide range, serving as a one-stop solution for all your QR code requirements.</li>
          <li>User-Friendly: ZM QR Code Services stands out for its user-friendly interface, simplifying the creation, management, and deployment of QR codes without any technical complications.</li>
          <li>Support and Reliability: We offer dedicated support for sales and resellers, ensuring that you receive the necessary assistance to effectively integrate QR codes into your business strategy.</li>
        </ul>
        <p>These attributes position ZM QR Code Services as an appealing option for businesses seeking to harness QR codes for marketing, sales, and operational efficiency.</p>
      </div>
    </section>
  );
};

export default AboutUs;
