import React, { useState, useEffect } from 'react';
import  axiosPrivate  from '../../api/axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../styles/loginPage.css';
import { ToastContainer, toast, useToast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ResetPassword = () => {

  const rooturl = process.env.rooturl
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  useEffect(() => {
    if (!token) {
      setError('Invalid or missing token. Please try the reset password process again.');
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');
    if (password === confirmPassword) {
      if (!token || token.length === 0) {
        setError('Invalid token. Please try the reset password process again.');
        return;
      }

      try {
        setLoading(true);
        const response = await axiosPrivate.post(
          '/api/user/reset-password',
          { Password: password }, // Request body
          {
            headers: {
              Authorization: token // Authorization header with 'Bearer'
            }
          }
        );
        
        const res = response?.data;
        setSuccess(res.success);

        setPassword('');
        setConfirmPassword('');
        toast('Your passward is succesfully updated.');
        setTimeout(() => {
          navigate('/login');
        }, 3000); // 3-second delay
      } catch (err) {
        const errorMessage = err?.response?.data?.message || err?.message || 'Error fetching server information';
        setError(errorMessage);
      } finally {
        setLoading(false);
      }
    } else {
      setError("Passwords do not match");
    }
  };

  return (
    <div className="body-login">
      <img className='login-people' src='/contact/contact-people.png' height={700} alt='people' />
      <div className='login-container'>
        {error && <div style={{ color: 'red', marginBottom: '15px', textAlign: 'center' }}>{error}</div>}
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>New Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Confirm Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">
            {loading ? <span className="spinner" /> : 'Submit'}
          </button>
        </form>
      </div>
      <ToastContainer /> 
    </div>
  );
};

export default ResetPassword;
