import React from "react";

const RefundPolicy = () => {
    return (
        <div className="bg-gray-100 py-8 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto bg-white shadow-md rounded-lg p-8">
                <h2 className="text-3xl font-bold mb-4 text-gray-800 text-center">
                    Refund Policy
                </h2>
                <p className="text-gray-600 mb-6">
                    We appreciate your purchase, and we are here to assist you in case
                    you're not satisfied with your purchase. Please read the details of
                    our refund policy below.
                </p>
                <h3 className="text-xl font-semibold text-gray-700 mb-2">
                    Refund Eligibility:
                </h3>
                <ul className="list-disc list-inside mb-6 text-gray-600">
                    <li>Products must be returned within 30 days of delivery.</li>
                    <li>The product must be unused and in the same condition as received.</li>
                    <li>Proof of purchase is required for all refunds.</li>
                </ul>
                <h3 className="text-xl font-semibold text-gray-700 mb-2">
                    Refund Process:
                </h3>
                <p className="text-gray-600 mb-6">
                    To initiate a refund, please contact us at <a href="mailto:connect@zmqrcode.com" className="text-blue-500">
                        connect@zmqrcode.com
                    </a>. Once
                    your return is received and inspected, we will notify you of the
                    approval or rejection of your refund. If approved, your refund will
                    be processed within 5-7 business days.
                </p>
                <h3 className="text-xl font-semibold text-gray-700 mb-2">
                    Refund Method:
                </h3>
                <p className="text-gray-600 mb-6">
                    Refunds will be credited back to your original method of payment,
                    including credit/debit card or other payment methods used during the
                    purchase.
                </p>
                <h3 className="text-xl font-semibold text-gray-700 mb-2">Exceptions:</h3>
                <p className="text-gray-600 mb-6">
                    Some items are non-refundable, such as downloadable software or
                    digital goods. Please contact our support for more information.
                </p>
                <p className="text-gray-600 text-sm italic text-center">
                    For any queries related to refunds, please reach out to us at{" "}
                    <a href="mailto:connect@zmqrcode.com" className="text-blue-500">
                        connect@zmqrcode.com
                    </a>
                    .
                </p>
            </div>
        </div>
    );
};

export default RefundPolicy;
