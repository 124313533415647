import React from "react";
import "../../styles/viewall.css";
import { Link } from "react-router-dom";
const servicesData = [
  {
    title: "Video",
    icon: "/viewall/video.png",
    description:
      "Keep your special moments alive by sharing your videos and experiencing the laughter, joy, and adventures with your loved ones with our Unique QR Code.",
    url: "videos",
  },
  {
    title: "Image Gallery",
    icon: "/viewall/images.png",
    description:
      "Establish your photography or picture collection using an Image QR code. Simplify the process for potential clients to view your portfolio effortlessly with a quick scan.",
    url: "images",
  },
  {
    title: " Links",
    icon: "/viewall/links.jpg",
    description:
      "Develop an enhanced alternative to Link tree that combines social media links, business websites, and other features on a single page. This page should be compatible with QR codes and NFC technology..",
    url: "links",
  },
  {
    title: "Audio",
    icon: "/viewall/audio.jpg",
    description:
      "Hear the Magic with an Audio QR Code, Your Sound, One Scan Away Easily share your audio creations:Create an Audio QR code linking directly to your audio portfolio.Listeners can quickly scan the code with their smartphone to hear your work..",
    url: "audios",
  },
  {
    title: "WiFi",
    icon: "/viewall/wifi.jpg",
    description:
      "Share your WiFi SSID and password through a Wi-Fi QR code so that individuals can easily scan it to access and connect to your WiFi network.",
    url: "wifi",
  },
  {
    title: "Documents",
    icon: "/viewall/docs.png",
    description:
      "Generate a QR code for customers to scan and access PDFs, manuals, and other documents related to your products, services, and business.",
    url: "coming-soon",
  },
  {
    title: "Location",
    icon: "/viewall/maps.png",
    description:
      "Generate Google Map QR Codes for easy access to your location by customers. Encourage them to rate your business and enhance your popularity within the local community.",
    url: "location",
  },
  {
    title: "UPI",
    icon: "/viewall/upi.png",
    description:
      "Receive payments from any UPI app by utilizing a UPI QR code, enabling individuals to scan the code and make online payments through any UPI platform.",
    url: "coming-soon",
  },
  {
    title: "Website",
    icon: "/viewall/website.png",
    description:
      "Generate a URL QR code and distribute it to your offline audience to connect them to your online platforms. Drive traffic to your website, blog posts, and various online destinations with this popular QR Code solution.",
    url: "coming-soon",
  },
  {
    title: "Business Cards",
    icon: "/viewall/vbcard.jpg",
    description:
      "Generate polished digital business cards swiftly using pre-designed templates. Seamlessly connect with others, leave a lasting impact, and forge meaningful relationships.",
    url: "coming-soon",
  },
  {
    title: "Coupons",
    icon: "/viewall/coupons.png",
    description:
      "Generate coupon pages for your discount promotions to boost engagement with your in-store visitors. Create an appealing page quickly in just a few minutes.",
    url: "coming-soon",
  },
  {
    title: "Social Media",
    icon: "/viewall/socialmedia.png",
    description:
      "Utilized by leading global brands, craft your ideal social media landing page to transform your in-person visitors into dedicated fans and followers.",
    url: "coming-soon",
  },
  {
    title: "Google Forms",
    icon: "/viewall/googleforms.png",
    description:
      "Generate QR Codes for Google Forms to gather responses from offline sources. Easily access comprehensive analytics and manage everything at your fingertips.",
    url: "coming-soon",
  },
  {
    title: "Facebook",
    icon: "/viewall/facebook.png",
    description:
      "Create stunning QR code landing pages for your Facebook business to increase traffic and likes! Turn your offline visitors into online followers.",
    url: "coming-soon",
  },
  {
    title: "PDF Gallery",
    icon: "/viewall/pdf.png",
    description:
      "Generate a QR code for your PDF Gallery allowing customers to scan and access various PDFs and manuals related to your products and services.",
    url: "coming-soon",
  },
  {
    title: "App Download",
    icon: "/viewall/appd.jpg",
    description:
      "Boost your app downloads by utilizing a top-rated QR code page for your app. Many leading Fortune 100 companies have adopted this strategy, which includes analytics and extensive customization options.",
    url: "coming-soon",
  },
  {
    title: "Medical Alert",
    icon: "/viewall/medical.jpg",
    description:
      "Generate a QR Code Medical Alert ID Tag for immediate emergency support. Quickly share vital medical information through scanning. Easily update and access it from anywhere, at any time.",
    url: "coming-soon",
  },
  {
    title: "Google",
    icon: "/viewall/google.png",
    description:
      "Generate QR codes for specific keywords in Google search results. Easily direct users to the search results page of your chosen keywords with a simple scan.",
    url: "coming-soon",
  },
  {
    title: "Youtube",
    icon: "/viewall/youtube.png",
    description:
      "Guide viewers to your YouTube video by utilizing this QR Code Generator tool. It's an ideal method to boost traffic to your online video channel from the physical world for your marketing and promotional efforts.",
    url: "coming-soon",
  },
  {
    title: "Menu Card",
    icon: "/viewall/menu.jpg",
    description:
      "Craft a flawless touchless menu page swiftly using the Restaurant Menu QR Code. Opt for round or food-shaped QR codes. Modify your menu whenever necessary without incurring expenses for reprinting and redistributing.",
    url: "coming-soon",
  },
  {
    title: "Google Reviews",
    icon: "/viewall/googlereviews.jpg",
    description:
      "A straightforward yet impactful method to boost your Google ratings is by transforming your satisfied customers into brand ambassadors. Simply allow them to scan and rate your services.",
    url: "coming-soon",
  },
  {
    title: "Google Calender",
    icon: "/viewall/calender.jpg",
    description:
      "Generate a Google QR Code for quick scanning to save events and reminders directly to your calendar. Effortlessly share meeting details and event information with your audience.",
    url: "coming-soon",
  },
  {
    title: "Feedback Forms",
    icon: "/viewall/feedback.webp",
    description:
      "Link your feedback forms to a QR Code to instantly gather reviews and feedback from your customers, complete with in-depth analytics.",
    url: "coming-soon",
  },
  {
    title: "Landing Page",
    icon: "/viewall/landingpage.png",
    description:
      "Design attractive and intelligent landing pages to highlight your products and marketing initiatives using Landing page QR codes. Create and launch within minutes.",
    url: "coming-soon",
  },
  {
    title: "Google Meet",
    icon: "/viewall/meet.png",
    description:
      "Generate a QR code for your Google Meet session and encourage your audience to join. Creating these QR codes is straightforward and adds a touch of convenience for your users.",
    url: "coming-soon",
  },
  {
    title: "Resume",
    icon: "/viewall/resume.jpg",
    description:
      "Differentiate yourself by incorporating QR codes for your resume and portfolio. Allow potential employers to quickly access and learn more about you.",
    url: "coming-soon",
  },
  {
    title: "Zoom Meeting",
    icon: "/viewall/zoom.jpg",
    description:
      "Zoom meetings are highly popular. Now, promoting your Zoom meeting is made effortless with these quick and simple QR codes.",
    url: "coming-soon",
  },
  {
    title: "Presentation",
    icon: "/viewall/present.jpg",
    description:
      "Attract your audience to your presentation by using QR Codes. Simply input the URL of your PPT or PDF to grant easy access to your valuable work.",
    url: "coming-soon",
  },
  {
    title: "Whatsapp",
    icon: "/viewall/whatsapp.png",
    description:
      "Enable customers to reach out to you via WhatsApp in the physical world by scanning your WhatsApp QR Code. This strategy enhances customer connections and boosts satisfaction levels.",
    url: "coming-soon",
  },
  {
    title: "Events",
    icon: "/viewall/events.jpg",
    description:
      "Create flawless landing pages for your Events to boost signups and engagement. Encourage social media following. Access comprehensive analytics for detailed insights.",
    url: "coming-soon",
  },
  {
    title: "Pet ID Tag",
    icon: "/viewall/petid.jpg",
    description:
      "Generate a QR code for your pet's collar with their ID details to provide your contact information and pet's details. This will help individuals reach out to you promptly if they find your lost pet. Receive notifications when the code is scanned.",
    url: "coming-soon",
  },
  {
    title: "Meeting",
    icon: "/viewall/meeting.jpg",
    description:
      "Share your meeting URL to boost participation by using a Meeting QR code. Generate a QR code for various meeting platforms like Skype, Google Meet, Zoom, and others.",
    url: "coming-soon",
  },
  {
    title: "Text",
    icon: "/viewall/text.png",
    description:
      "Show text directly on users' phones by scanning a Text QR code. Share instant messages with just one scan - no internet connection is necessary for this type of QR Code.",
    url: "coming-soon",
  },
  {
    title: "Email",
    icon: "/viewall/email.jpg",
    description:
      "Enable individuals to reach out to you via email using an Email QR code. Direct users to their default email application with the message and recipient's email address already filled in.",
    url: "coming-soon",
  },
  {
    title: "SMS",
    icon: "/viewall/sms.jpg",
    description:
      "Generate an SMS QR code for easy connection via text message. Provide a pre-written message and the recipient's phone number to streamline communication.",
    url: "coming-soon",
  },
  {
    title: "Event Tickets",
    icon: "/viewall/event.jpg",
    description:
      "Generate a QR Code for your event tickets to streamline the ticketing process and entry management. Effortlessly manage tickets and facilitate smooth check-ins for your attendees.",
    url: "coming-soon",
  },
];

const ServiceCard = ({ title, icon, description, url }) => (
  <Link to={url} className="service-card-link">
    <div className="service-car">
      <div className="service-card-header">
        <img
          src={icon}
          alt={`${title}  icon`}
          className="service-card-icon"
        />
      </div>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  </Link>
);

const ViewAll = () => (
  <div>
    <div className="View">
      <div className="Cont">
        <h1>ZM QR Code Services</h1>
        <p>
          As the preferred QR Code solution provider for leading global brands,
          we offer comprehensive services for all your QR Code marketing and
          business requirements.
        </p>
        <ul>
          <li>
            Enhance customer engagement and elevate scan rates by over 50% with
            our exclusive QR shapes and stickers.
          </li>
          <li>
            Gain deeper insights into your campaign performance with advanced
            real-time analytics.
          </li>
          <li>
            Streamline your QR operations with automated bulk uploads and folder
            organization.
          </li>
          <li>
            Begin with our free plan and seamlessly transition to customized
            paid options designed for businesses of all sizes.
          </li>
        </ul>
      </div>
      <img src="/viewall/services.png" alt="QR Code Example" />
    </div>
    <div className="services-grid">
      {servicesData.map((service, index) => (
        <ServiceCard
          key={index}
          title={service.title}
          icon={`${service.icon}`}
          description={service.description}
          url={service.url}
        />
      ))}
    </div>
  </div>
);

export default ViewAll;
