import React from 'react';
import '../../styles/pricing.css';
import { useNavigate } from 'react-router-dom';
import './pricingpage.css'; 

const PricingPage = () => {


  const navigate = useNavigate();
  const moreinfo=()=>{
    navigate('/pricingpage');
  }
  const moreinfoI=()=>{
    navigate('/industry');
  }
  return (
    <div style={{fontFamily:"revert"}}>
      <div class="container">
      <div class="plan_cards">
     <div class="industry_card">
      <h1  style={{color:"blue"}}>For Industry</h1>
      <span>Designed for businesses that need advanced QR code generation and management features. This plan offers scalable solutions for creating, tracking, and analyzing QR codes, with capabilities tailored to meet the complex needs of larger organizations
      </span>
      <br></br>
      <div><h3 style={{color:"#FFA500"}}>Storage</h3><p>upto 1TB</p></div>
      <div><h3  style={{color:"blue"}}>Features</h3>
      <ul style={{listStyle:'none'}}>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Custom Branding</li>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i> Bulk QR Code Generation</li>
        <li> <i class="fa-solid fa-check" style={{color:'green'}}></i>Advanced Analytics</li>
      </ul>
      </div>
      <div><h3 >Benefits</h3>
      <ul style={{listStyle:'none'}}>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Enhanced Brand Visibility</li>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Efficient Operations</li>
       
      
      </ul>
      </div>
      <button class="btn-primary" onClick={moreinfoI}>More info</button>
     </div>
     <div class="individual_card">
     <h1  style={{color:"blue"}}>For Individual</h1>
     <span>Ideal for personal use, offering an easy and affordable way to generate and use QR codes. This plan is perfect for individuals who need QR codes for personal projects, event invitations, or small-scale marketing efforts. 
     </span>
     <br></br>
     <div><h3  style={{color:"#FFA500"}}>Storage</h3><p>Required Storage Database</p></div>

     <div><h3  style={{color:"blue"}}>Features</h3>
      <ul style={{listStyle:'none'}}>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Basic QR Code Creation</li>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Customization Options</li>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Personal Use Analytics</li>
      
      </ul>
      </div>
      <div><h3 >Benefits</h3>
      <ul style={{listStyle:'none'}}>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Cost-Effective</li>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Ease of Use</li>
        <li><i class="fa-solid fa-check" style={{color:'green'}}></i>Versatility</li>
      </ul>
      </div>
     <button class="btn-primary" onClick={moreinfo}>More info</button>
     </div>
     </div>
     </div>
    </div>
  );
};

export default PricingPage;

export function Industry(){
  const enterprisePrices = [
    { range: "Required Storage Database", price: "The intention of discussing a possible price for industries benfit" },
  ];
  return(
    <div className="pricing-page">
    <h1 className="title">Pricing Plans</h1>
 
   
     
     <div className="pricing-section">
<h2 className="category-title">Industry</h2>
<table className="pricing-table">
  <thead>
    <tr>
      <th>Storage</th>
      <th>Price</th>
    </tr>
  </thead>
  <tbody>
    {enterprisePrices.map((item, index) => (
      <tr key={index}>
        <td>{item.range}</td>
        <td>{item.price}</td>
      </tr>
    ))}
  </tbody>
</table>
</div> 
</div>
  )
}
