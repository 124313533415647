import React,{ useState }  from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import BusinessCard from "./businesscard";
import SwiperCore, { Navigation } from 'swiper';
import './BusinessCard.css';
import Carousel from 'react-bootstrap/Carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FaAudioDescription, FaFacebook, FaWhatsapp, FaGoogle, FaMapMarkedAlt, FaMeetup, FaListUl, FaFilePowerpoint, FaTag } from 'react-icons/fa';
function BusinessCard_new (){
    const services = [
        { name: 'Audio', icon: <FaAudioDescription /> },
        { name: 'Coupon', icon: <FaListUl /> },
        { name: 'Facebook', icon: <FaFacebook /> },
        { name: 'WhatsApp', icon: <FaWhatsapp /> },
        { name: 'Google Forms', icon: <FaGoogle /> },
        { name: 'Google Meet', icon: <FaMeetup /> },
        { name: 'Location', icon: <FaMapMarkedAlt /> },
        { name: 'Meeting', icon: <FaMeetup /> },
        { name: 'Menu Card', icon: <FaListUl /> },
        { name: 'PPT', icon: <FaFilePowerpoint /> }
      ];
      const initialCardsData = [
        { id: 1, title: 'Card 1', content: 'This is the content of card 1.' },
        { id: 2, title: 'Card 2', content: 'This is the content of card 2.' },
        { id: 3, title: 'Card 3', content: 'This is the content of card 3.' },
      ];
      const cardTemplates = [
        { id: 4, title: 'Template Card 1', content: 'This is the content of template card 1.' },
        { id: 5, title: 'Template Card 2', content: 'This is the content of template card 2.' },
        { id: 6, title: 'Template Card 3', content: 'This is the content of template card 3.' },
      ];
      const [cardsData, setCardsData] = useState(initialCardsData);
      const [expandedCard, setExpandedCard] = useState(null);
      const [newCardTitle, setNewCardTitle] = useState('');
      const [newCardContent, setNewCardContent] = useState('');
      const [showDropdown, setShowDropdown] = useState(false);
        
      const toggleCard = (id) => {
        setExpandedCard(expandedCard === id ? null : id);
      };
    

      const images = [
        'https://via.placeholder.com/135x233?text=Image+1',
        'https://via.placeholder.com/135x233?text=Image+2',
        'https://via.placeholder.com/135x233?text=Image+3',
        'https://via.placeholder.com/135x233?text=Image+4',
        'https://via.placeholder.com/135x233?text=Image+5',
        'https://via.placeholder.com/135x233?text=Image+6',
        'https://via.placeholder.com/135x233?text=Image+7',
        'https://via.placeholder.com/135x233?text=Image+8',
        'https://via.placeholder.com/135x233?text=Image+9',
        'https://via.placeholder.com/135x233?text=Image+10',
        'https://via.placeholder.com/135x233?text=Image+11',
        'https://via.placeholder.com/135x233?text=Image+12',
        'https://via.placeholder.com/135x233?text=Image+13',
        'https://via.placeholder.com/135x233?text=Image+14',
        'https://via.placeholder.com/135x233?text=Image+15',
        'https://via.placeholder.com/135x233?text=Image+16',
        'https://via.placeholder.com/135x233?text=Image+17',
        'https://via.placeholder.com/135x233?text=Image+18'
      ];
      //Toggle 
      const [isOn, setIsOn] = useState(false); // Track the toggle state

      const handleToggle = () => {
        setIsOn(!isOn); // Toggle the state
      };
      
    return ( 
        <div className="flex-container" >
          <div className="services-container">
            {services.map((service, index) => (
                <div key={index} className="service-item">
                    <div className="service-icon">{service.icon}</div>
                    <div className="service-name">{service.name}</div>
                 </div>
            ))}
            <a href="https://example.com" class="more_icon">
               <i class="fa-solid fa-angles-right"></i>
               <span>More</span>
            </a>
        </div>
        <div class="containers" style={{display:'flex',height:'auto'}}>
        <div class="card card-1" >
            <div class="header-container">
            <div class="header-left">
                <h2>Digital Business Cards</h2>
            </div>
            <div class="header-right">
                <div class="toggle">
                <i className={`fa ${isOn ? 'fa-toggle-on' : 'fa-toggle-off'} toggle-icon`}
                   onClick={handleToggle}
                   title="Toggle Option"
                ></i>
                </div>
                <p>Bulk Upload</p>
                <div class="info">
                    <i class="fa-solid fa-info-circle info-icon"></i>
                    <div class="info-content">
                        This is additional information that appears when you hover over the icon.
                    </div>
                </div>
                
            </div>
        </div>
        <div class="steps">
                <ul class="horizontal-list">
                    <li><span>1</span><div class="list_item_style">Content</div></li>
                    <li><i class="fa-light fa-greater-than icon"></i></li>
                    <li><span>2</span><div class="list_item_style">Design/settings</div></li>
                    <li><i class="fa-light fa-greater-than icon"></i></li>
                    <li><span>3</span><div class="list_item_style">QR Code</div></li>
                </ul>
        </div>
        <form>
        <div class="form-first-container">
        <div class="page-url-info">
            <h2>Your Page URL</h2>
            <p>(Once saved, cannot be changed later)</p>
        </div>
        <div class="url-input-container">
            <button  class="disabled-button">Linko.page/</button>
            <input type='text'  class="url-input"/>   
        </div>
        <span class="font-12 text-muted" style={{marginLeft:'10px'}}>*minimum 5 characters required</span>
        </div>
        <div className="carousel-container">
      <h2>Page Template</h2>
      <p>(Click on the template you like)</p>
      <div className="scroll-container">
        <div className="image-wrapper">
          {images.map((image, index) => (
            <img key={index} src={image} alt={`Image ${index + 1}`} />
          ))}
        </div>
      </div>
    </div>
    <div className="collapsible-cards-container">
      {cardsData.map((card) => (
        <div
          key={card.id}
          className={`card ${expandedCard === card.id ? 'expanded' : 'collapsed'}`}
          onClick={() => toggleCard(card.id)}
        >
          <div className="card-header">
            <h2>{card.title}</h2>
            <FontAwesomeIcon icon={expandedCard === card.id ? faMinus : faPlus} />
          </div>
          {expandedCard === card.id && <p>{card.content}</p>}
        </div>
      ))}
      <div>
        <button ><i class="fa-solid fa-plus"></i>Add Component</button>
        
      </div>
      
    </div>
        </form>
        </div>
        <div class="card card-2">
            <h2>Heading for Card 2</h2>
            <p>Content for the second card. This card takes up 30% of the width.</p>
        </div>
        </div>
      </div>
     );
};

export default BusinessCard_new ;