import React, { useState } from 'react';

const plans = [
  {
    name: 'Free',
    storage: [
      { range: '0 to 100 MB', price: '199/-' },
      { range: '0 to 250 MB', price: '299/-' },
    ],
    icon: '💰', // Example icon, replace with actual icon or image
  },
  {
    name: 'Standard',
    storage: [
      { range: '0 to 500 MB', price: '499/-' },
      { range: '0 to 1 GB', price: '999/-' },
      { range: '0 to 2 GB', price: '1599/-' },
    ],
    icon: '💸', // Example icon, replace with actual icon or image
  },
  {
    name: 'Premium',
    storage: [
      { range: '0 to 3 GB', price: '1999/-' },
      { range: '0 to 5 GB', price: '2999/-' },
      // Add more storage options as needed
    ],
    icon: '🤑', // Example icon, replace with actual icon or image
  },
];

const Plan = () => {
  const [activePlan, setActivePlan] = useState(null);

  const handleClick = (plan) => {
    setActivePlan(activePlan === plan ? null : plan);
  };

  return (
    <div className="plans-container">
      {plans.map((plan) => (
        <div key={plan.name} className="plan-column">
          <div className="plan-card">
            <div
              className="plan-icon"
              onClick={() => handleClick(plan.name)}
            >
              {plan.icon}
            </div>
            <div className="plan-details">
              {activePlan === plan.name && (
                <div className="plan-price">
                  <p>Price: {plan.storage.find(item => item.range === '0 to 1 GB')?.price}</p>
                </div>
              )}
              <div className="plan-storage">
                {plan.storage.map((item, index) => (
                  <div key={index} className="storage-item">
                    {item.range}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Plan;
