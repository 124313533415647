import React, { useState } from 'react';
import QRCode from 'qrcode';
import '../input.css';

const VideoUploadForm = () => {
    const [file, setFile] = useState(null);
    const [qrCodeData, setQrCodeData] = useState('');
    const [styleOption, setStyleOption] = useState('default');
    const [error, setError] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = (e) => {
        e.preventDefault();
        if (!file) return setError('Please upload a file');
        // Handle file upload logic here
        console.log('File uploaded:', file);
    };

    const generateQRCode = async () => {
        try {
            const data = await QRCode.toDataURL(file.name, {
                color: {
                    dark: styleOption === 'dark' ? '#000000' : '#0000FF', // Dark pixels
                    light: '#FFFFFF' // Light pixels
                },
            });
            setQrCodeData(data);
        } catch (error) {
            console.error('Error generating QR code:', error);
            setError('Failed to generate QR code');
        }
    };

    return (
        <div className='qr-code-form'>
            <div className='file-container'>
                <label htmlFor='file'>Video Upload</label>
                <input
                    type='file'
                    accept='video/*'
                    onChange={handleFileChange}
                    id='file'
                    required
                />
                <button onClick={handleUpload}>Upload</button>
            </div>
            
            <div className='style-options'>
                <label htmlFor='style-select'>Select QR Code Style:</label>
                <select
                    id='style-select'
                    value={styleOption}
                    onChange={(e) => setStyleOption(e.target.value)}
                >
                    <option value='default'>Default</option>
                    <option value='dark'>Dark</option>
                </select>
            </div>

            <button onClick={generateQRCode}>Generate QR Code</button>

            {error && <div style={{ color: 'red', marginBottom: '15px', textAlign: 'center' }}>{error}</div>}
            {qrCodeData && (
                <div className='qr-code'>
                    <img src={qrCodeData} alt='QR Code' />
                </div>
            )}
        </div>
    );
};

export default VideoUploadForm;
