import React, { useEffect, useState } from "react";
import axiosPrivate from "../../api/axios";
import { useParams } from "react-router-dom";

const ShowLinkData = () => {
    const params = useParams();
    const [wifiname, setWifiName] = useState("helo");
    const [pwd, setPwd] = useState("");
    const [nwtype, setNwtype] = useState("");
    const[linkType,setLinkType]=useState("");
    const[url,setUrl]=useState("")

    useEffect(() => {
        const fetchLinks = async () => {
            try {
                const response = await axiosPrivate.get('http://localhost:3500/api/qrcc/get-link/'+params.LinkId);

                if (response.status === 200) {
                    const { LinkType, LinkData } = response.data;
                    console.log('API response:', response.data);

                    // if (LinkType === "WIFI") {
                    //     const wifi = LinkData.split(":");
                    //     setWifiName(wifi[3]?.split(";")[0] || "");
                    //     setPwd(wifi[4]?.split(";;")[0] || "");
                    //     setNwtype(wifi[2]?.split(";")[0] || "");
                    //     setLinkType("WIFI");
                    // }
                    // else 
                    if(LinkType==="URL"){
                        setUrl(LinkData);
                        setLinkType('URL');
                    }
                    else if(LinkType==="LOCATION"){
                        setUrl(LinkData);
                        setLinkType('LOCATION');
                    }
                }
                else if(response.status === 203) {
                    setLinkType('Inactive')
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchLinks();
    }, [params]);

    return (
        <>
        {/* {linkType==='WIFI' &&(
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',  padding: '20px' }}>
            <div style={{ textAlign: 'center' }}>
                <h1>WiFi Details</h1>
                <div className="card" style={{ width: '100%', maxWidth: '500px', margin: '0 auto', padding: '20px' }}>
                    <form className="form-inline">
                        <div className="form-group mb-2" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label htmlFor="wifiName"><h2>Wi-Fi Name</h2></label>
                            <input
                                id="wifiName"
                                type="text"
                                value={wifiname}
                                className="form-control form-control-lg"
                                readOnly
                                disabled
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form-group mb-2" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label htmlFor="wifiPassword"><h2>Wi-Fi Password</h2></label>
                            <input
                                id="wifiPassword"
                                type="text"
                                value={pwd}
                                className="form-control form-control-lg"
                                readOnly
                                disabled
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="form-group mb-2" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                            <label htmlFor="networkType"><h2>Network Type</h2></label>
                            <input
                                id="networkType"
                                type="text"
                                value={nwtype}
                                className="form-control form-control-lg"
                                readOnly
                                disabled
                                style={{ width: '100%' }}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
        )} */}
        {linkType==='URL'&&(
            <div className="card">
                <a href={url}><h2>Click here to redirect the page</h2></a>
            </div>
        )}
         {linkType==='LOCATION'&&(
            <div className="card">
                <a href={url}><h2>Click here to redirect the page</h2></a>
            </div>
        )}
        {linkType==='Inactive'&&(
            <div className="card">
            <h2>Status is Inactive.Please Contact to the owner</h2>
        </div>
        )

        }
        </>
    );
};

export default ShowLinkData;
