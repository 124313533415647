import React, { useState } from 'react';
import { FaInstagram, FaFacebook, FaTwitter } from 'react-icons/fa';
import { faPhoneAlt, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import axiosPrivate from '../../api/axios';
import '../../styles/Footer.css';

const Footer = () => {
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    if (!email?.length)
      return setError('Email is required')

    try {
      setLoading(true);
      const response = await axiosPrivate.post('/api/newsletter/subscribe', {
        EmailId: email,
      });
      const res = response.data.message;
      setSuccess(res);
      setEmail('')
    } catch (err) {
      const errorMessage = err?.response?.data?.message || err?.message || 'error fetching server information';
      setError(errorMessage);
      console.log(err);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div>
          <div className="stay-connected">
            <h2>Stay Connected</h2>
            <p>Sign up for our newsletter and be the first to hear about Offers, updates, and tips</p>
            <form className="newsletter-form">
              <label htmlFor='foot-email'></label>
              <input id='foot-email' type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your email address" />
              <button onClick={handleSubmit} disabled={loading}>
                {loading ? <span className="spinner" /> : <img src="/footer/email.png" alt="Subscribe" />}
              </button>
            </form>
            {error && <div style={{ color: 'red', fontWeight: 'bold', marginBottom: '15px', textAlign: 'center' }}>{error}</div>}
            {success && <div style={{ color: '#2cf651', fontWeight: 'bold', marginBottom: '15px', textAlign: 'center' }}>{success}</div>}
          </div>
          <div className="join-community">
            <h2>Join The Community</h2>
            <div className="social-icons">
              {/* <a href="#"><FaLinkedin /></a>
              <a href="#"><FaYoutube /></a> */}
              <a href="https://www.instagram.com/zmqrcode?igsh=YzljYTk1ODg3Zg=="><FaInstagram /></a>
              <a href="https://www.facebook.com/profile.php?id=61559950102078"><FaFacebook /></a>
              <a href="https://x.com/zmqrcode"><FaTwitter /></a>
            </div>
          </div>
        </div>

        <div className="laptop-image-container">
        </div>
      </div>

      <div className='dummy'>
        <div className="company-details">
          <img src="/footer/ZMlogo.png" alt="" />
          <h3>ZM QR Code Services</h3>
          <p>Generate a unique QR Code for your brand with us...</p>
        </div>
        <div className='footer-links'>
          <div className="contact-us">
            <h2>Contact us</h2>
            <p>
              <FontAwesomeIcon icon={faPhoneAlt} /> <a href="tel:8639683839">86396 83839</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faEnvelope} /> <a href="mailto:connect@zmqrcode.com">connect@zmqrcode.com</a>
            </p>
            <p>
              <FontAwesomeIcon icon={faMapMarkerAlt} /> Ramaseetha Complex, 4th Floor, 5/4, Arundelpet, Guntur, AP, India 522002
            </p>
          </div>

          <div className="company-links">
            <h2>Company</h2>
            <ul>
              <li><HashLink smooth to="/#header">Home</HashLink></li>
              <li><Link to="/aboutus">About Us</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><HashLink smooth to="/#faq">FAQs</HashLink></li>
              <li><HashLink smooth to="/#contact">Contact Us</HashLink></li>
            </ul>
          </div>
          <div className="terms-links">
            <h2>Terms</h2>
            <ul>
              <li><Link to="/Terms">Terms And Conditions</Link></li>
              <li><Link to="/PP">Privacy Policy</Link></li>
              <li><Link to="/CP">Cookie Policy</Link></li>
            </ul>
          </div>
        </div>
      </div>


      <div className="footer-bottom">
        <p>© 2024. All Rights Reserved. ZM QR Code</p>
      </div>

    </footer>
  );
};

export default Footer;
