import React from 'react'
import Header from './header';
import Contact from './contact';
import Support from './support';
import FAQSection from './faq';
import Services from './services';
import AboutUs from './AboutUs';

const Home = () => {
    return (
        <>
            <Header />
            <Services />
            <AboutUs />
            <FAQSection />
            <Support />
            <Contact />
        </>
    )
}

export default Home