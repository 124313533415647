import React from 'react';
import '../../styles/header.css';
import { useNavigate } from 'react-router-dom';
import Contact from './contact';

const Header = () => {
    const navigate = useNavigate();
    return (
        <div className="header" id="header">
  
        <div className="hcontent">
     
          
        {/* <div style={{color:"black",listStyle:"decimal"}}>
            <h2>Generate your QR code in three easy steps:</h2>
            <ul>
                <li>Select the type</li>
                <li>Generate the QR code</li>
                <li>Customize and download</li>
            </ul>
          </div> */}

         
          <div className="tail">
            <button onClick={() => navigate('/login')}>Login</button>
          </div>
          <div className="head">
            <button onClick={() => navigate('/services')}>Generate your QR code</button>
          </div>
        </div>
      </div>
      
    );
}

export default Header;
