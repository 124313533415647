import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { useNavigate } from "react-router-dom";
import LoginModal from '../../authentication/LoginModal'

const Modal = ({ isOpen, onClose, onQrCodeUpdate, geturl }) => {
  useEffect(() => {
    if (geturl) {
      setUrl(geturl);
      onQrCodeUpdate(geturl);
    }
  }, [geturl, onQrCodeUpdate]);
  const [url, setUrl] = useState("https://qr-code-styling.com");
  const [fileExt, setFileExt] = useState("png");
  const qrCodeRef = useRef(null);
  const [selectedSection, setSelectedSection] = useState("DesignDots");
  const [savedColor, setSavedColor] = useState({ r: 0, g: 0, b: 0, a: 1 });
  const [selectedType, setSelectedType] = useState("rounded");
  const [selectCornerType, setselectCornerType] = useState("square");
  const [selectcornercolor, setSelectCornerColor] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const [morecornercolors, setmorecornercolors] = useState(false);
  //  const [activeItem, setActiveItem] = useState("");
  const [selectdottype, setselectdottype] = useState("");
  const [image, setImage] = useState("/Logos/ZMlogo.png");

  const [GColor1, setGColor1] = useState("#2FC3AD");
  const [GColor2, setGColor2] = useState("#2FC3AD");
  const [GColor3, setGColor3] = useState("#7D7DF4");

  const [offset1, setOffset1] = useState(0);
  const [offset2, setOffset2] = useState(0.5);
  const [offset3, setOffset3] = useState(1);

  const [gradienttype, setGradinetType] = useState("linear");

  const [rotation, setRotation] = useState(0);

  const [GColorc1, setGColorc1] = useState("#2FC3AD");
  const [GColorc2, setGColorc2] = useState("#2FC3AD");
  const [GColorc3, setGColorc3] = useState("#7D7DF4");

  const [offsetc1, setOffsetc1] = useState(0);
  const [offsetc2, setOffsetc2] = useState(0.5);
  const [offsetc3, setOffsetc3] = useState(1);

  const [gradienttypecorner, setGradinetTypecorner] = useState("linear");

  const [rotationc, setRotationc] = useState(0);

  const [GColord1, setGColord1] = useState("#2FC3AD");
  const [GColord2, setGColord2] = useState("#2FC3AD");
  const [GColord3, setGColord3] = useState("#7D7DF4");

  const [offsetd1, setOffsetd1] = useState(0);
  const [offsetd2, setOffsetd2] = useState(0.5);
  const [offsetd3, setOffsetd3] = useState(1);

  const [gradientdottype, setGradinetdotType] = useState("linear");

  const [rotationd, setRotationd] = useState(0);
  const [selectcornerdotcolor, setcornerdotColor] = useState("black");

  const [qrCodeImage, setQrCodeImage] = useState(null);

  const [Width, setWidth] = useState(300);
  const [Height, setHeight] = useState(300);
 const[open,setopen]=useState(false);

  const handleImage = (newimage) => {
    console.log("logo");
    setImage(newimage);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the uploaded image as the new QR code image
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    console.log("url", geturl);

    const qrCode = new QRCodeStyling({
      width: 280,
      height: 280,
      dotsOptions: {
        color: "black",
        type: "rounded",
      },
      backgroundOptions: {
        color: "white",
      },
      cornersSquareOptions: {
        type: "square",
        color: "black",
      },
      cornersDotOptions: {
        type: "circle",
        color: "black",
      },
    });

    if (qrCodeRef.current) {
      console.log("sss qrcoderef: ", qrCodeRef.current, "qrcode", qrCode);
      qrCode.append(qrCodeRef.current);
    }

    return () => {
      if (qrCodeRef.current) qrCodeRef.current.innerHTML = ""; // Clean up QR code
    };
  }, []);
  useEffect(() => {
    if (qrCodeRef.current) {
      const qrCode = new QRCodeStyling({
        width: Width,
        height: Height,
        data: geturl,
        image: image,
        dotsOptions: {
          type: selectedType,
          gradient: {
            type: gradienttype,
            rotation: rotation,
            colorStops: [
              { offset: offset1, color: GColor1 },
              { offset: offset2, color: GColor2 },
              { offset: offset3, color: GColor3 },
            ],
          },
        },
        cornersSquareOptions: {
          type: selectCornerType,
          gradient: {
            type: gradienttypecorner,
            rotation: rotationc,
            colorStops: [
              { offset: offsetc1, color: GColorc1 },
              { offset: offsetc2, color: GColorc2 },
              { offset: offsetc3, color: GColorc3 },
            ],
          },
        },
        cornersDotOptions: {
          type: selectdottype,
          gradient: {
            type: gradientdottype,
            rotation: rotationd,
            colorStops: [
              { offset: offsetd1, color: GColord1 },
              { offset: offsetd2, color: GColord2 },
              { offset: offsetd3, color: GColord3 },
            ],
          },
          imageOptions: {
            crossOrigin: "anonymous",
            margin: 0,
          },
        },
      });
      qrCode.append(qrCodeRef.current);
      qrCode.update({ data: url });
      if (qrCodeRef.current) {
        qrCode.append(qrCodeRef.current);
      }
      setQrCodeImage(qrCode);
    }
  }, [
    geturl,
    Width,
    Height,
    url,
    savedColor,
    selectedType,
    selectCornerType,
    selectcornercolor,
    selectdottype,
    image,
    GColor1,
    GColor2,
    GColor3,
    gradienttype,
    offset1,
    offset2,
    offset3,
    rotation,
    GColorc1,
    GColorc2,
    GColorc3,
    gradienttypecorner,
    offsetc1,
    offsetc2,
    offsetc3,
    rotationc,
    selectdottype,
    GColord1,
    GColord2,
    GColord3,
    gradientdottype,
    rotationd,
    offsetd1,
    offsetd2,
    offsetd3,
  ]);
  const handleDotColorChange = (event) => {
    setSavedColor(event.target.value);
    setGColor1(event.target.value);
    setGColor2(event.target.value);
    setGColor3(event.target.value);
    console.log("Selected color:", event.target.value); // Optional: log the selected color
  };

  const handleGColor1Change = (event) => {
    setGColor1(event.target.value);
  };
  const handleGColor2Change = (event) => {
    setGColor2(event.target.value);
  };
  const handleGColor3Change = (event) => {
    setGColor3(event.target.value);
  };

  const handleGradientType = (event) => {
    setGradinetType(event.target.value);
  };

  const handleoffset1 = (event) => {
    setOffset1(event.target.value);
  };
  const handleoffset2 = (event) => {
    setOffset2(event.target.value);
  };
  const handleoffset3 = (event) => {
    setOffset3(event.target.value);
  };

  const handleReset = () => {
    setOffset1(0);
    setOffset2(0.5);
    setOffset3(1);
  };
  const handleResetc = () => {
    setOffsetc1(0);
    setOffsetc2(0.5);
    setOffsetc3(1);
  };
  const handleDotReset = () => {
    setOffsetd1(0);
    setOffsetd2(0.5);
    setOffsetd3(1);
  };

  // Handler for slider input changes
  const handleSliderChange = (event) => {
    const value = event.target.value;
    setRotation(value);
  };

  const onSelectcornerChange = (value) => {
    setselectCornerType(value);
  };

  const handleCornerColors = (event) => {
    setSelectCornerColor(event.target.value);
    setGColorc1(event.target.value);
    setGColorc2(event.target.value);
    setGColorc3(event.target.value);
  };

  const handleGColor1CornerChange = (event) => {
    setGColorc1(event.target.value);
  };
  const handleGColor2CornerChange = (event) => {
    setGColorc2(event.target.value);
  };
  const handleGColor3CornerChange = (event) => {
    setGColorc3(event.target.value);
  };
  const handleGradientCornerType = (event) => {
    setGradinetTypecorner(event.target.value);
  };

  const handleoffsetc1 = (event) => {
    setOffsetc1(event.target.value);
  };
  const handleoffsetc2 = (event) => {
    setOffsetc2(event.target.value);
  };
  const handleoffsetc3 = (event) => {
    setOffsetc3(event.target.value);
  };
  const handleCornerSliderChange = (event) => {
    const value = event.target.value;
    setRotationc(value);
  };

  const onSelectdotChange = (value) => {
    setselectdottype(value);
  };

  const handleCornerDotColorChange = (event) => {
    setcornerdotColor(event.target.value);
    setGColord1(event.target.value);
    setGColord2(event.target.value);
    setGColord3(event.target.value);
  };
  const handleGColor1dotChange = (event) => {
    setGColord1(event.target.value);
  };
  const handleGColor2dotChange = (event) => {
    setGColord2(event.target.value);
  };
  const handleGColor3dotChange = (event) => {
    setGColord3(event.target.value);
  };
  const handleGradientDotType = (event) => {
    setGradinetdotType(event.target.value);
  };
  const handleoffsetd1 = (event) => {
    setOffsetd1(event.target.value);
  };
  const handleoffsetd2 = (event) => {
    setOffsetd2(event.target.value);
  };
  const handleoffsetd3 = (event) => {
    setOffsetd3(event.target.value);
  };
  const handleSliderDotChange = (event) => {
    const value = event.target.value;
    setRotationd(value);
  };
  const handleTypeChange = (type) => {
    setSelectedType(type);
  };
  const onSelectChange = (value) => {
    handleTypeChange(value);
  };

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };
  const onDownloadClick = () => {
    if (localStorage.getItem("accessToken")) {
      if (qrCodeImage) {
        qrCodeImage.download({ extension: fileExt });
      }
    } else {
    setopen(true);
    }
    window.location.reload();
  };

  const handleListItemClick = (section) => {
    setSelectedSection(section);
  };

  const handleHeight = (event) => {
    const value = event.target.value;
    setHeight(value);
    setWidth(value);
  };
  const handleLoginSuccess = () => {
    if (qrCodeImage) {
      qrCodeImage.download({ extension: fileExt });
    }
  };

  return (
    <div className="card">
      <div class="card collapsed">
        <div className="card-header">
          <h2>Design Your QR Code</h2>
        </div>
      </div>

      <div>
        <div class="modals">
          <div class="cardv-1 card">
            <div class="list-container">
              <ul className="list-ul">
                <li
                  className="list-item"
                  onClick={() => handleListItemClick("DesignDots")}
                >
                  <i class="fa-solid fa-circle"></i> DesignDots
                </li>
                <li
                  className="list-item"
                  onClick={() => handleListItemClick("DesignCorners")}
                >
                  <i class="fa-solid fa-square"></i> DesignCornerSquares
                </li>

                <li
                  className="list-item"
                  onClick={() => handleListItemClick("DesignCornerDots")}
                >
                  <i class="fa-regular fa-circle-dot"></i> DesignCornerDots
                </li>
                <li
                  className="list-item"
                  onClick={() => handleListItemClick("Logos")}
                >
                  <i class="fa-solid fa-font-awesome"></i> Logos
                </li>
                <li
                  className="list-item"
                  onClick={() => handleListItemClick("Size")}
                >
                  <i class="fa-solid fa-expand"></i>Size
                </li>
              </ul>
            </div>
            {selectedSection === "DesignDots" && (
              <>
                <div>
                  <div class="containerDD">
                    <span class="h2s">Dot Style</span>
                    <div class="DDstyle" >
                      <ul
                        style={{
                          display: "flex",
                          verticalAlign: "middle",
                          listStyle: "none",
                          margin:'0px'
                        }}
                      >
                        <li onClick={() => handleTypeChange("dots")}>
                          <img
                            src="/shapes/dot.png"
                            style={{ width: "40%" }}
                            alt="Dot Shape"
                          />
                        </li>
                        <li onClick={() => handleTypeChange("square")}>
                          <img
                            src="/shapes/square.png"
                            style={{ width: "50%" }}
                            alt="Square Shape"
                          />
                        </li>
                        <li onClick={() => handleTypeChange("rounded")}>
                          <img
                            src="/shapes/rounded.png"
                            style={{ width: "50%" }}
                            alt="Rounded Shape"
                          />
                        </li>
                        <li onClick={() => handleTypeChange("extra-rounded")}>
                          <img
                            src="/shapes/extrarounded.png"
                            style={{ width: "40%" }}
                            alt="Extra Rounded Shape"
                          />
                        </li>
                        <li onClick={() => handleTypeChange("classy")}>
                          <img
                            src="/shapes/classy.png"
                            style={{ width: "35%" }}
                            alt="Classy Shape"
                          />
                        </li>
                        <li onClick={() => handleTypeChange("classy-rounded")}>
                          <img
                            src="/shapes/classyrounded.png"
                            style={{ width: "35%" }}
                            alt="Classy Rounded Shape"
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr className="hline" />
                  <div class="containerDD">
                    <span class="h2s">Single Colour</span>
                    <div class="DDstyle">
                      <input
                        id="color-selector"
                        type="color"
                        value={savedColor}
                        onChange={handleDotColorChange}
                      ></input>
                    </div>
                  </div>
                  <hr className="hline" />
                  <div class="containerDD">
                    <span class="h2s">Gradient Colour</span>
                    <div class="DDstyleGradient">
                      <input
                        id="color-selector"
                        type="color"
                        value={GColor1}
                        onChange={handleGColor1Change}
                      ></input>
                      <input
                        id="color-selector"
                        type="color"
                        value={GColor2}
                        onChange={handleGColor2Change}
                      ></input>
                      <input
                        id="color-selector"
                        type="color"
                        value={GColor3}
                        onChange={handleGColor3Change}
                      ></input>
                    </div>
                  </div>
                  <hr className="hline" />
                  <div class="containerDD">
                    <span class="h2s">Gradient Type</span>
                    <div class="DDstyle">
                      <select
                        id="style-selector"
                        value={gradienttype}
                        onChange={handleGradientType}
                      >
                        <option value="radial">Radial</option>
                        <option value="linear">Linear</option>
                      </select>
                    </div>
                  </div>
                  <hr className="hline" />
                  <div class="containerDD">
                    <span class="h2s">Gradient offsets</span>
                    <div class="DDstyleinput">
                      <label for="offset-input1">offset1</label>
                      <input
                        id="offset-input1"
                        type="number"
                        value={offsetd1}
                        onChange={handleoffsetd1}
                        min="0"
                        max="1"
                        step="0.1"
                        class="color-input"
                      ></input>
                      <label for="offset-input2">offset2</label>
                      <input
                        id="offset-input2"
                        type="number"
                        value={offsetd2}
                        onChange={handleoffsetd2}
                        min="0"
                        max="1"
                        step="0.1"
                        class="color-input"
                      ></input>
                      <label for="offset-input3">offset3</label>
                      <input
                        id="offset-input3"
                        type="number"
                        value={offsetd3}
                        onChange={handleoffsetd3}
                        min="0"
                        max="1"
                        step="0.1"
                        class="color-input"
                      ></input>
                      <input
                        type="reset"
                        value="Reset"
                        onClick={handleDotReset}
                      ></input>
                    </div>
                  </div>
                  <hr className="hline" />
                  <div class="containerDD">
                    <span class="h2s">Rotation</span>
                    <div class="DDstyle">
                      <input
                        type="range"
                        min="0"
                        max="360"
                        step="1"
                        id="rotation-slider"
                        class="slider"
                        onChange={handleSliderChange}
                      />
                      <div class="value-display">
                        <span id="rotation-value">{rotation}°</span>
                      </div>
                    </div>
                  </div>
                  <hr className="hline" />
                </div>
              </>
            )}
            {selectedSection === "Logos" && (
              <div>
                {" "}
                <div class="logoheader">
                  <h3>Select a Logo</h3>
                  <form>
                    <label htmlFor="upload-logo" style={{ cursor: "pointer" }}>
                      <div style={{ display: "block" }}>
                        {" "}
                        <i class="fa-solid fa-plus fa-2xl"></i>
                      </div>
                    </label>
                  </form>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  <ul className="logolist">
                    <li class="logocard" onClick={() => handleImage("")}>
                      {" "}
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid #ccc",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <label
                          htmlFor="upload-logo"
                          style={{ cursor: "pointer" }}
                        >
                          <div style={{ display: "block" }}> None</div>
                        </label>
                      </div>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo1.png")}
                    >
                      <img
                        src="/Logos/logo1.png"
                        style={{ width: "150px", height: "100px" }}
                      ></img>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo2.jpg")}
                    >
                      <img
                        src="/Logos/logo2.jpg"
                        style={{ width: "150px", height: "100px" }}
                      ></img>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo7.png")}
                    >
                      <img
                        src="/Logos/logo7.png"
                        style={{ width: "100px", height: "100px" }}
                      ></img>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo8.png")}
                    >
                      <img
                        src="/Logos/logo8.png"
                        style={{ width: "100px", height: "100px" }}
                      ></img>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo3.webp")}
                    >
                      <img
                        src="/Logos/logo3.webp"
                        style={{ width: "150px", height: "100px" }}
                      ></img>
                    </li>

                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo5.jpg")}
                    >
                      <img
                        src="/Logos/logo5.jpg"
                        style={{ width: "150px", height: "100px" }}
                      ></img>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo6.png")}
                    >
                      <img
                        src="/Logos/logo6.png"
                        style={{ width: "100px", height: "100px" }}
                      ></img>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo9.png")}
                    >
                      <img
                        src="/Logos/logo9.png"
                        style={{ width: "100px", height: "100px" }}
                      ></img>
                    </li>
                    <li
                      class="logocard"
                      onClick={() => handleImage("/Logos/logo10.jpg")}
                    >
                      <img
                        src="/Logos/logo10.jpg"
                        style={{ width: "100px", height: "100px" }}
                      ></img>
                    </li>

                    <li class="logocard">
                      {" "}
                      <div
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "1px solid #ccc",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <label
                          htmlFor="upload-logo"
                          style={{ cursor: "pointer" }}
                        >
                          <div style={{ display: "block" }}>
                            {" "}
                            <i class="fa-solid fa-plus fa-2xl"></i>
                          </div>
                          <input
                            type="file"
                            id="upload-logo"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={handleFileUpload}
                          />
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )}
            {selectedSection === "DesignCorners" && (
              <>
                   <div class="containerDD">
                    <span class="h2s">Corner Style</span>
                    <div class="DDstyle" >
                      <ul
                        style={{
                          display: "flex",
                          verticalAlign: "middle",
                          listStyle: "none",
                          margin:'0px'
                        }}
                      >
                        <li onClick={() => onSelectcornerChange("dots")}>
                          <img
                            src="/shapes/dot.png"
                            style={{ width: "40%" }}
                            alt="Dot Shape"
                          />
                        </li>
                        <li onClick={() =>onSelectcornerChange("square")}>
                          <img
                            src="/shapes/square.png"
                            style={{ width: "50%" }}
                            alt="Square Shape"
                          />
                        </li>
                       
                        <li onClick={() => onSelectcornerChange("extra-rounded")}>
                          <img
                            src="/shapes/extrarounded.png"
                            style={{ width: "40%" }}
                            alt="Extra Rounded Shape"
                          />
                        </li>
                        
                      </ul>
                    </div>
                  </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Single Colour</span>
                  <div class="DDstyle">
                    <input
                      id="color-selector"
                      type="color"
                      value={selectcornercolor}
                      onChange={handleCornerColors}
                    ></input>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Gradient Colour</span>
                  <div class="DDstyleGradient">
                    <input
                      id="color-selector"
                      type="color"
                      value={GColorc1}
                      onChange={handleGColor1CornerChange}
                    ></input>
                    <input
                      id="color-selector"
                      type="color"
                      value={GColorc2}
                      onChange={handleGColor2CornerChange}
                    ></input>
                    <input
                      id="color-selector"
                      type="color"
                      value={GColorc3}
                      onChange={handleGColor3CornerChange}
                    ></input>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Gradient Type</span>
                  <div class="DDstyle">
                    <select
                      id="style-selector"
                      value={gradienttypecorner}
                      onChange={handleGradientCornerType}
                    >
                      <option value="radial">Radial</option>
                      <option value="linear">Linear</option>
                    </select>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Gradient offsets</span>
                  <div class="DDstyleinput">
                    <label for="offset-input1">offset1</label>
                    <input
                      id="offset-input1"
                      type="number"
                      value={offsetc1}
                      onChange={handleoffsetc1}
                      min="0"
                      max="1"
                      step="0.1"
                      class="color-input"
                    ></input>
                    <label for="offset-input2">offset2</label>
                    <input
                      id="offset-input2"
                      type="number"
                      value={offsetc2}
                      onChange={handleoffsetc2}
                      min="0"
                      max="1"
                      step="0.1"
                      class="color-input"
                    ></input>
                    <label for="offset-input3">offset3</label>
                    <input
                      id="offset-input3"
                      type="number"
                      value={offsetc3}
                      onChange={handleoffsetc3}
                      min="0"
                      max="1"
                      step="0.1"
                      class="color-input"
                    ></input>
                    <input
                      type="reset"
                      value="Reset"
                      onClick={handleResetc}
                    ></input>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Rotation</span>
                  <div class="DDstyle">
                    <input
                      type="range"
                      min="0"
                      max="360"
                      step="1"
                      id="rotation-slider"
                      class="slider"
                      onChange={handleCornerSliderChange}
                    />
                    <div class="value-display">
                      <span id="rotation-value">{rotationc}°</span>
                    </div>
                  </div>
                </div>
                <hr className="hline" />
              </>
            )}
            {selectedSection === "Size" && (
              <form>
                <div>
                  <label>Size </label>
                  <input
                    type="range"
                    min="100"
                    max="500"
                    value={Height}
                    onChange={handleHeight}
                    id="rotation-slider"
                  ></input>
                  <div class="value-display">
                    <span id="rotation-value">{Height}</span>
                  </div>
                </div>
              </form>
            )}
            {selectedSection === "DesignCornerDots" && (
              <>
                <div class="containerDD">
                    <span class="h2s">Corner Dot Style</span>
                    <div class="DDstyle" >
                      <ul
                        style={{
                          display: "flex",
                          verticalAlign: "middle",
                          listStyle: "none",
                          margin:'0px'
                        }}
                      >
                          <li onClick={() => onSelectdotChange ("none")}>
                         None
                        </li>
                        <li onClick={() => onSelectdotChange ("dots")}>
                          <img
                            src="/shapes/dot.png"
                            style={{ width: "40%" }}
                            alt="Dot Shape"
                          />
                        </li>
                        <li onClick={() =>onSelectdotChange ("square")}>
                          <img
                            src="/shapes/square.png"
                            style={{ width: "50%" }}
                            alt="Square Shape"
                          />
                        </li>
                       
                      
                        
                      </ul>
                    </div>
                  </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Single Colour</span>
                  <div class="DDstyle">
                    <input
                      id="color-selector"
                      type="color"
                      value={selectcornerdotcolor}
                      onChange={handleCornerDotColorChange}
                    ></input>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Gradient Colour</span>
                  <div class="DDstyleGradient">
                    <input
                      id="color-selector"
                      type="color"
                      value={GColord1}
                      onChange={handleGColor1dotChange}
                    ></input>
                    <input
                      id="color-selector"
                      type="color"
                      value={GColord2}
                      onChange={handleGColor2dotChange}
                    ></input>
                    <input
                      id="color-selector"
                      type="color"
                      value={GColord3}
                      onChange={handleGColor3dotChange}
                    ></input>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Gradient Type</span>
                  <div class="DDstyle">
                    <select
                      id="style-selector"
                      value={gradientdottype}
                      onChange={handleGradientDotType}
                    >
                      <option value="radial">Radial</option>
                      <option value="linear">Linear</option>
                    </select>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Gradient offsets</span>
                  <div class="DDstyleinput">
                    <label for="offset-input1">offset1</label>
                    <input
                      id="offset-input1"
                      type="number"
                      value={offset1}
                      onChange={handleoffset1}
                      min="0"
                      max="1"
                      step="0.1"
                      class="color-input"
                    ></input>
                    <label for="offset-input2">offset2</label>
                    <input
                      id="offset-input2"
                      type="number"
                      value={offset2}
                      onChange={handleoffset2}
                      min="0"
                      max="1"
                      step="0.1"
                      class="color-input"
                    ></input>
                    <label for="offset-input3">offset3</label>
                    <input
                      id="offset-input3"
                      type="number"
                      value={offset3}
                      onChange={handleoffset3}
                      min="0"
                      max="1"
                      step="0.1"
                      class="color-input"
                    ></input>
                    <input
                      type="reset"
                      value="Reset"
                      onClick={handleReset}
                    ></input>
                  </div>
                </div>
                <hr className="hline" />
                <div class="containerDD">
                  <span class="h2s">Rotation</span>
                  <div class="DDstyle">
                    <input
                      type="range"
                      min="0"
                      max="360"
                      step="1"
                      id="rotation-slider"
                      class="slider"
                      onChange={handleSliderDotChange}
                    />
                    <div class="value-display">
                      <span id="rotation-value">{rotationd}°</span>
                    </div>
                  </div>
                </div>
                <hr className="hline" />
              </>
            )}
          </div>

          <div class="cardv-2 card">
            {/* <div class="qr_container"> */}
            <div className="qr-code-container" ref={qrCodeRef} />

            <p style={{ textAlign: "center" }}>customize QR design!!!</p>

            {/* </div> */}
            <div className="downloadcustom">
              <select onChange={onExtensionChange} value={fileExt}>
                <option value="png">PNG</option>
                <option value="jpeg">JPEG</option>
                <option value="webp">WEBP</option>
              </select>
              <button className="customdownload" onClick={onDownloadClick}>
                Download <i className="fa-solid fa-download"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {open &&
      <LoginModal onClose={()=>setopen(false)} onLoginSuccess={handleLoginSuccess}/>}
    </div>
  );
};

export default Modal;
//if (!isOpen) return null;
