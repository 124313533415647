import React, { useState, useEffect } from 'react';
import axiosPrivate from '../../api/axios';
import '../../styles/ADash.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProfileSection = () => {

  const [userDetails, setUserDetails] = useState({
    firstname: '',
    lastname: '',
    mobile: '',
    email: '',
  });
  const [error, setError] = useState(null);
  const [isProfileActive, setIsProfileActive] = useState(false);
  const [isPasswordActive, setIsPasswordActive] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isFormActive, setIsFormActive] = useState(false);
  const [isNotificationsActive,setIsNotificationsActive]=useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosPrivate.get('/api/user/get-details', {
          headers: {
            Authorization: localStorage.getItem('accessToken'), // Add 'Bearer' before the token
          },
        });
        const userData = response.data;
        setUserDetails({
          firstname: userData.FirstName,
          lastname: userData.LastName,
          mobile: userData.PhoneNumber,
          email: userData.EmailId,
        });
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError('Error fetching user details. Please try again later.');
      }
    };

    fetchData();
  }, []); // Empty dependency array to run once on component mount

  if (error) {
    return <div>{error}</div>;
  }

  const handleProfileToggle = () => {
    setIsProfileActive((prev) => !prev);
  };

  const handlePasswordToggle = () => {
    setIsPasswordActive((prev) => !prev);
  };
  const handleToggle=()=>{
    setIsNotificationsActive((prev)=>!prev);
  }

  const handleProfileSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosPrivate.post('/api/user/update-details',
        {
          FirstName: userDetails.firstname,
          LastName: userDetails.lastname,
          PhoneNumber: userDetails.mobile,
          EmailId: userDetails.email,
        },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken'), // Add 'Bearer' before the token
          },
        }
      );

      if (response.status === 200) {
        toast('User details updated successfully!');
        setIsProfileActive(false);
      }
    } catch (error) {
      console.error("Error updating user details:", error);
      toast.error('Failed to update user details. Please try again later.');
    }
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      setPasswordError('New password and confirm password do not match.');
      toast.error('New password and confirm password do not match.');
      return;
    }

    try {
      const response = await axiosPrivate.post('/api/user/update-password',
        {
          currentPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: localStorage.getItem('accessToken'), // Add 'Bearer' before the token
          },
        }
      );

      if (response.status === 200) {
        setIsPasswordActive(false);
        toast.success('Password updated successfully!');
        setCurrentPassword('');
        setNewPassword('');
        setConfirmNewPassword('');
      }

    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          toast.error('Invalid current password or new password');
        } else if (error.response.status === 401) {
          toast.error('Unauthorized access');
        } else if (error.response.status === 500) {
          toast.error('Server error, please try again later');
        } else {
          toast.error(error.response.data.message);
        }
      } else {
        toast.error('Network error, please check your connection');
      }
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here
  };

  return (
    <div className="profile_container">
      {/* Profile Card */}
      <div className="card profile_card">
        <div className="form_header">
          <h2>Personal Information</h2>
          <span className="toggle_button" onClick={handleProfileToggle}>
            <i className={`fas fa-${isProfileActive ? 'toggle-on' : 'toggle-off'} fa-lg`}></i>
          </span>
        </div>
        <form className="edit_profile_form" onSubmit={handleProfileSubmit}>
          <div className="form_item">
            <label htmlFor="firstname" className="form_label">Firstname</label>
            <input
              type="text"
              className="form_control"
              id="firstname"
              value={userDetails.firstname}
              placeholder="Enter your firstname"
              disabled={!isProfileActive}
              onChange={(e) => setUserDetails({ ...userDetails, firstname: e.target.value })}
            />
          </div>
          <div className="form_item">
            <label htmlFor="lastname" className="form_label">Lastname</label>
            <input
              type="text"
              className="form_control"
              id="lastname"
              value={userDetails.lastname}
              placeholder="Enter your lastname"
              disabled={!isProfileActive}
              onChange={(e) => setUserDetails({ ...userDetails, lastname: e.target.value })}
            />
          </div>
          <div className="form_item">
            <label htmlFor="mobile" className="form_label">Mobile</label>
            <input
              type="text"
              className="form_control"
              id="mobile"
              value={userDetails.mobile}
              placeholder="Mobile"
              disabled={!isProfileActive}
              onChange={(e) => setUserDetails({ ...userDetails, mobile: e.target.value })}
            />
          </div>
          <div className="form_item">
            <label htmlFor="email1" className="form_label">Email</label>
            <input
              type="text"
              className="form_control"
              id="email1"
              value={userDetails.email}
              placeholder="Enter your email"
              disabled={!isProfileActive}
            />
          </div>
          <button
            type="submit"
            className="btn_primary"
            disabled={!isProfileActive}
          >
            Save Changes
          </button>
        </form>
      
      </div>

      {/* Password Card */}
      <div className="card password_card">
        <div className="form_header">
          <h2>Password Management</h2>
          <span className="toggle_button" onClick={handlePasswordToggle}>
            <i className={`fas fa-${isPasswordActive ? 'toggle-on' : 'toggle-off'} fa-lg`}></i>
          </span>
        </div>
        <form className="edit_password_form" onSubmit={handlePasswordChange}>
          <div className="form_item">
            <label htmlFor="current-password" className="form_label">Current Password</label>
            <input
              type="password"
              className="form_control"
              id="current-password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current password"
              disabled={!isPasswordActive}
            />
          </div>
          <div className="form_item">
            <label htmlFor="new-password" className="form_label">New Password</label>
            <input
              type="password"
              className="form_control"
              id="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New password"
              disabled={!isPasswordActive}
            />
          </div>
          <div className="form_item">
            <label htmlFor="confirm-new-password" className="form_label">Confirm New Password</label>
            <input
              type="password"
              className="form_control"
              id="confirm-new-password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              placeholder="Confirm new password"
              disabled={!isPasswordActive}
            />
          </div>
          {passwordError && <p className="error">{passwordError}</p>}
          <button type="submit" className="btn_primary" disabled={!isPasswordActive}>
            Change Password
          </button>
        </form>
      
      </div>

      <div className="card notifications_card">
        <div className="form_header">
          <h2>Notifications</h2>
          <span className="toggle_button" onClick={handleToggle}>
            <i className={`fas fa-${isNotificationsActive ? 'toggle-on' : 'toggle-off'} fa-lg`}></i>
          </span>
        </div>
        <form className="edit_notifications_form" onSubmit={handleFormSubmit}>
          <div className="form_item">
            <input type="checkbox" disabled={!isNotificationsActive} className="form_checkbox" />
            <label>Notification 1</label>
          </div>
          <div className="form_item">
            <input type="checkbox" disabled={!isNotificationsActive} className="form_checkbox" />
            <label>Notification 2</label>
          </div>
          <div className="form_item">
            <input type="checkbox" disabled={!isNotificationsActive} className="form_checkbox" />
            <label>Notification 3</label>
          </div>
          <button type="submit" className="btn_primary" disabled={!isNotificationsActive}>
            Save Changes
          </button>
        </form>
        <ToastContainer/>
      </div>

    
    </div>
  );
};

export default ProfileSection;
