import React, { useEffect, useState } from 'react';
import '../../styles/faq.css';

const FAQSection = () => {
    const [accounts, setAccounts] = useState([]);
    const [activeQuestion, setActiveQuestion] = useState('');

    useEffect(() => {
        setAccounts([
            { qn: "How do I create an account with ZMQRCode Services?", ans: " To create an account, click on the register link on our website, fill in the required details, and submit the form. You will receive a confirmation email to verify your account." },
            { qn: "What information do I need to provide to create an account?", ans: "You need to provide your name, email address, and create a password." },
            { qn: "I forgot my password. How can I reset it?", ans: "Click on the 'Forgot Password' link on the login page, enter your registered email address, and follow the instructions sent to your email to reset your password." },
            { qn: "How do I update my account information?", ans: "Log in to your account, go to the 'Account Settings' section, and update your personal details, contact information, or password as needed." },
            { qn: "Can I change my email address associated with my account?", ans: "Yes, you can change your email address in the 'Account Settings' section of your account dashboard." }
        ])
    }, []);

    //     qrCodes: [
    //         { qn: "What is a QR Code?", ans: " A QR Code(Quick Response Code) is a type of matrix barcode that can be scanned using a smartphone or QR Code reader to access information instantly." },
    //         { qn: "What is a QR Code generator?", ans: "A QR Code generator is a tool that creates QR Codes for different purposes, such as opening websites, viewing PDFs, listening to music, watching videos, and more." },
    //         { qn: "What kind of information can be stored in a QR Code?", ans: " QR Codes can store URLs, contact details, calendar events, WiFi network information, plain text, email addresses, phone numbers, and more." },
    //         { qn: "What do the letters QR stand for?", ans: "QR stands for Quick Response." },
    //         { qn: "How to read/scan a QR Code?", ans: "Use your smartphone’s camera app or a dedicated QR Code reader app to scan the QR Code and access its content." },
    //         { qn: "Can QR Codes be scanned without an app?", ans: "Many modern smartphones can scan QR Codes using their built-in camera app without needing a separate app." },
    //     ]

    const toggleQuestion = (index) => {
        setActiveQuestion(activeQuestion === index ? null : index);
    };

    return (
        <div id='faq' className="faq-section">
            <h2>Frequently Asked Questions</h2>
            <div className="faq-items">
                {accounts.map((item, index) => (
                    <div className="faq-item" key={index}>
                        <div
                            className={`faq-question ${activeQuestion === index ? 'active' : ''}`}
                            onClick={() => toggleQuestion(index)}
                        >
                            {item.qn}
                        </div>
                        {activeQuestion === index && (
                            <div className="faq-answer">
                                {item.ans}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
