import React from "react";
import { Link } from "react-router-dom";
import {
    FaAudioDescription,
    FaFacebook,
    FaWhatsapp,
    FaGoogle,
    FaMapMarkedAlt,
    FaMeetup,
    FaListUl,
    FaFilePowerpoint,
  } from "react-icons/fa";
const Services=()=>{
    const services = [
        { name: "Audios", icon: <FaAudioDescription />, link: "/services/audios" },
        {
          name: "Videos",
          icon: <i class="fa-brands fa-youtube"></i>,
          link: "/services/videos",
        },
        {
          name: "Images",
          icon: <i class="fa-solid fa-image"></i>,
          link: "/services/images",
        },
        { name: "Links",icon:<i class="fa-solid fa-link"></i>, link: "/services/links" },
        { name: "Location", icon: <FaMapMarkedAlt />, link: "/services/location" },
        { name: "WiFi", icon: <i class="fa-solid fa-wifi"></i>, link: "/services/wifi" },
        { name: "Forms", icon: <FaGoogle />, link: "/forms" },

        { name: "Meeting", icon: <FaMeetup />, link: "/meeting" },
        { name: "PPT", icon: <FaFilePowerpoint />, link: "/ppt" },
      ];
      return(
        <div className="services-container">
        {services.map((service, index) => (
          <Link to={service.link} key={index} className="service-item">
            <div className="service-icon">{service.icon}</div>
            <div className="service-name">{service.name}</div>
          </Link>
        ))}
        <Link to="/services" className="more_icon">
          <i className="fa-solid fa-angles-right"></i>
          <span>More</span>
        </Link>
      </div>
      )
}
export default Services;