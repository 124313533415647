import React from "react";
import { useEffect, useState } from 'react';
import "../../styles/AboutUsPage.css";

const FAQSection = () => {
  const [accounts, setAccounts] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState('');

  useEffect(() => {
    setAccounts([
      { qn: "After a QR code is generated, is it possible to add other content to it?", ans: "Before generating a QR code, it is possible to add multiple types of content. However, once the QR code is generated, it cannot be modified to include additional content. Therefore, it is essential to ensure all necessary information is included before the QR code is created." },
      { qn: "Using a single mobile number or email, how many QR codes can we generate?", ans: "There is no limit to the number of QR codes you can generate using a single mobile number or email. You can create as many QR codes as needed for various purposes without any restrictions" },
      { qn: "Can we generate a QR code without a password?", ans: "Yes, QR codes can be generated with or without a password. Depending on the level of security you require, you can choose to protect the QR code with a password or leave it unprotected for easy access." },
      { qn: "Can we download an invoice through a QR code?", ans: "Yes, you can download an invoice through a QR code. In addition to invoices, our QR code services allow you to access various other documents and services, making it a versatile tool for business operations." },
      { qn: "How can I contact customer support for QR codes?", ans: "You can contact our customer support team by visiting our website and obtaining the contact details provided there. Alternatively, you can send us an email at connect@zmqrcode.com, and our support team will assist you with your queries and concerns." },
      { qn: "Can I make payments using a QR code?", ans: "Yes, you can easily make payments using our QR code. This feature enables quick and secure transactions, making it convenient for both businesses and customers." },
      { qn: "Can I delete my saved data on a QR code?", ans: "No, once a QR code is generated, it is not possible to delete the data it contains. The QR code remains static with the information encoded in it at the time of creation." },
      { qn: "Will I get a refund if I cancel my subscription?", ans: "No, refunds are not provided if you cancel your subscription. It is advisable to review our subscription terms and ensure the service meets your needs before committing" },
      { qn: "If I delete my QR account, will my data get erased?", ans: "Your data will be stored until the validity period is completed. Once the validity period expires, your data will be erased. Deleting your QR account does not immediately erase your data." },
      { qn: "After the plan expires, what should I do?", ans: "After your plan expires, you should renew your account using the options available on your dashboard. Renewing your plan will ensure continued access to our QR code services and prevent any loss of data." },
      { qn: "What is the validity of a QR code?", ans: "he validity of a QR code is typically one year. After this period, you may need to renew the QR code to ensure it remains active and functional." },
      { qn: "How much does it cost to renew my QR code annually?", ans: "he annual renewal cost for a QR code is Rs. 99. This fee ensures that your QR code remains active and you continue to have access to our services." },
      { qn: "Will I lose my data if I don’t renew my QR code?", ans: "Yes, if you do not renew your QR code, you will lose your data after the validity period expires. It is important to renew your QR code within the specified period to retain access to your data and services." },
      { qn: "Can I use my QR code for multiple services?", ans: "Yes, you can use a single QR code for multiple services. This versatility allows you to streamline various business functions and enhance the efficiency of your operations using a single QR code." }




    ])
  }, []); const toggleQuestion = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <div id='faq'>
      <h2>Frequently Asked Questions</h2>
      <div className="faq-items">
        {accounts.map((item, index) => (
          <div className="faq-item" key={index}>
            <div
              className={`faq-question ${activeQuestion === index ? 'active' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              {item.qn}
            </div>
            {activeQuestion === index && (
              <div className="faq-answer">
                {item.ans}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );


};

const AboutUS = () => {
  return (
    <div className="aboutUs-div">
      <div className="aboutUs-h1">
        <h1>ZM QR Code Services</h1>
      </div>

      <section className="color-section">
        <div className="about-zm">
          <div>
            <h2>Who We Are</h2>
            <p>
              At <strong>ZM QR Code Services</strong>, we specialize in
              providing cutting-edge QR code solutions that empower both
              businesses and individuals to connect in innovative and seamless
              ways. We are driven by our passion for technology and our
              commitment to delivering high-quality, reliable, and customizable
              solutions that meet the unique needs of each of our clients.
            </p>
          </div>
          <img
            src="/AboutQr/zm.png"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
        </div>
      </section>

      <section className="color-section">
        <h2>Our Mission and Vision</h2>

        <div className="about-zm">
          <img
            src="/AboutQr/AboutQR-1.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
          <div>
            <h3>Mission</h3>
            <p>
              Our mission at ZM QR Code Services is to revolutionize the way
              businesses and individuals engage with their audiences through the
              power of QR codes. We strive to offer solutions that are not only
              technologically advanced but also user-friendly and accessible to
              everyone. Our goal is to enhance interactions, streamline
              processes, and drive growth by providing top-tier QR code
              services.
            </p>
          </div>
        </div>

        <div className="about-zm">
          <div>
            <h3>Vision</h3>
            <p>
              We envision a world where QR codes are an integral part of
              everyday interactions, enhancing experiences and providing
              seamless access to information. We aim to be at the forefront of
              this technological evolution, continuously innovating and adapting
              to meet the ever-changing needs of the market.
            </p>
          </div>
          <img
            src="/AboutQr/AboutQR-2.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
        </div>
      </section>

      <section className="color-section">
        <div className="about-zm">
          <div>
            <h2>Our Services</h2>
            <p>
              At ZM QR Code Services, we offer a comprehensive range of QR code
              services designed to cater to various needs. Our offerings include:
            </p>
          </div>
        </div>

        <div className="about-zm">
          <div>
            <h3>Custom QR Code Generation</h3>
            <p>
              We provide bespoke QR code generation services that allow businesses
              and individuals to create QR codes tailored to their specific needs.
              Whether its for marketing campaigns, product packaging, event
              management, or personal projects, our custom QR codes are designed to
              deliver optimal performance and user engagement.
            </p>
          </div>

          <img
            src="/AboutQr/AboutQR-6.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
        </div>

        <div className="about-zm">
          <div>
            <h4>QR Codes for Personal Use</h4>
            <ul>
              <li>
                <strong>Video:</strong> Generate QR codes that link directly to
                videos, making it easy to share multimedia content with your
                audience.
              </li>
              <li>
                <strong>Image Gallery:</strong> Create QR codes that direct users to
                an image gallery, perfect for showcasing personal photo collections
                or creative projects.
              </li>
              <li>
                <strong>Links:</strong> Use QR codes to share links to websites,
                social media profiles, or any other online content effortlessly.
              </li>
              <li>
                <strong>Audio:</strong> Generate QR codes that link to audio files,
                ideal for sharing podcasts, music, or voice messages.
              </li>
              <li>
                <strong>Documents:</strong> Share various document formats such as
                Word files, spreadsheets, or presentations through QR codes.
              </li>
              <li>
                <strong>PDF:</strong> Create QR codes that link to PDF files,
                providing a convenient way to distribute digital documents like
                brochures, eBooks, or reports.
              </li>
              <li>
                <strong>Digital Business Cards:</strong> Create a QR code that links
                to your digital business card, making networking seamless and
                eco-friendly.
              </li>
              <li>
                <strong>Event Invitations:</strong> Generate QR codes for event
                invitations, providing guests with easy access to event details and
                RSVPs.
              </li>
              <li>
                <strong>Personalized Gifts:</strong> Add a QR code to gifts that
                links to a personalized video message or photo album.
              </li>
              <li>
                <strong>Social Media Links:</strong> Share your social media
                profiles easily with QR codes that connect directly to your
                accounts.
              </li>
            </ul>
          </div>
        </div>

        <div className="about-zm">
          <img
            src="/AboutQr/AboutQR-7.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
          <div>
            <h4>QR Codes with Cloud Services</h4>
            <ul>
              <li>
                <strong>Cloud Storage Integration:</strong> Store your QR code data
                securely in the cloud, allowing for easy updates and management.
              </li>
              <li>
                <strong>Dynamic Content Management:</strong> Use our cloud services
                to update the content linked to your QR codes in real-time without
                changing the code itself.
              </li>
              <li>
                <strong>Analytics and Reporting:</strong> Access detailed analytics
                and reports through our cloud platform, helping you track and
                analyze the performance of your QR codes.
              </li>
            </ul>
          </div>
        </div>

        <div className="about-zm">
          <div>
            <h3>QR Code Analytics</h3>
            <p>
              Understanding how your QR codes are being used is crucial for
              measuring the success of your campaigns. Our advanced QR code
              analytics provide detailed insights into scan rates, user
              demographics, and geographic locations, enabling businesses and
              individuals to make informed decisions and refine their strategies.
            </p>
          </div>
        </div>

        <div className="about-zm">
          <div>
            <h3>Dynamic QR Codes</h3>
            <p>
              Dynamic QR codes offer flexibility and convenience, allowing users to
              update the content linked to a QR code without changing the code
              itself. This feature is perfect for ongoing promotions, seasonal
              campaigns, personal projects, and any situation where content needs to
              be regularly updated.
            </p>

            <h3>QR Code Design and Branding</h3>
            <p>
              A well-designed QR code can significantly enhance your image. Our
              design team works closely with clients to create QR codes that are
              visually appealing and consistent with their branding guidelines. We
              offer a variety of design options, including logo integration, custom
              colors, and unique patterns.
            </p>
          </div>

          <img
            src="/AboutQr/AboutQR-8.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
        </div>

        <div className="about-zm">
          <img
            src="/AboutQr/AboutQR-9.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
          <div>
            <h3>Mobile-Optimized Landing Pages</h3>
            <p>
              To ensure a seamless user experience, we offer mobile-optimized
              landing page creation services. These pages are designed to provide
              quick access to information, promote products or services, and capture
              user data effectively.
            </p>

            <h3>Secure QR Codes</h3>
            <p>
              Security is a top priority at ZM QR Code Services. We offer secure QR
              code solutions that include encryption and password protection to
              ensure that sensitive information is only accessible to authorized
              users.
            </p>
          </div>
        </div>
      </section>

      <section className="color-section">
        <h2>Why Choose ZM QR Code Services?</h2>

        <div className="about-zm">
          <div>
            <h3>Customer-Centric Approach</h3>
            <p>
              We believe in putting our customers first. Our services are
              designed with the client’s needs in mind, and we work closely with
              each client to understand their specific requirements and deliver
              solutions that exceed their expectations.
            </p>
            <h3>Innovation and Technology</h3>
            <p>
              At ZM QR Code Services, we are committed to staying ahead of the
              curve by embracing the latest technologies and continuously
              innovating our services. Our state-of-the-art solutions are
              designed to provide the highest levels of performance,
              reliability, and security.
            </p>
          </div>
          <img
            src="/AboutQr/AboutQR-3.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
        </div>
        <div className="about-zm">
          <img
            src="/AboutQr/AboutQR-4.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />

          <div>
            <h3>Comprehensive Support</h3>
            <p>
              We offer comprehensive support to ensure that our clients get the
              most out of our services. From initial consultation to ongoing
              maintenance, our team is always available to assist with any
              questions or concerns.
            </p>

            <h3>Affordability and Value</h3>
            <p>
              We believe that high-quality QR code services should be accessible
              to businesses of all sizes and individuals. Our pricing is
              competitive, and we offer a range of packages designed to provide
              maximum value for your investment.
            </p>
          </div>
        </div>
      </section>

      <section className="color-section">
        <div className="about-zm">
          <div>
            <h2>Get in Touch</h2>
            <p>
              We invite you to discover the power of QR codes with ZM QR Code
              Services. Whether you are looking to enhance your marketing
              efforts, improve customer engagement, streamline operations, or
              create personalized experiences, we have the perfect solution for
              you. Contact us today to learn more about our services and how we
              can help you thrive.
            </p>
          </div>
          <img
            src="/AboutQr/AboutQR-5.jpg"
            height={350}
            width={350}
            alt="QR Code Introduction"
          />
        </div>
      </section>

      <section className="color-section">
        <FAQSection />
      </section>
    </div>
  );
}

export default AboutUS;
