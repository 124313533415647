import React from 'react'

const NotFound = () => {
    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "2em",
            fontWeight: "bold",
            height: "92vh",
        }}>Not Found</div>
    )
}

export default NotFound