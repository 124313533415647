import React, {useState } from "react";
import Modal from "../BASE/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Services from "../BASE/Services";
import "../BASE/Modal.css";
import "../BASE/Base.css";
import MainQR from "../BASE/MainQR";
import axiosPrivate from "../../../api/axios";
import { FRONTEND_BASE_URL } from '../../../config';
function Location() {
  const [linkName, setlinkName] = useState("");
  const[linkData,setlinkData]=useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosPrivate.post(
        "/api/user/upload-link",
       {
       LinkName:linkName,
       LinkType:"Location",
       LinkData:linkData 
       },
        {
          headers : {
            Authorization: localStorage.getItem("accessToken")
          }
        }
        
      );
      toast.success("successfully uploaded");
      setGenURL(
        `${FRONTEND_BASE_URL}/showlinkdata/${response.data._id}`
      );
     
    } catch (error) {
      toast.error("An error occured...");
      console.error("Error uploading link:", error);
    }
  };
  const [modalOpen, setModalOpen] = useState(true);
  const [genURL, setGenURL] = useState("https://www.zmqrcode.com/");
  
  const [qrCodeDataUrl,setQrCodeDataUrl] = useState("");
  const handleQrCodeUpdate = (dataUrl) => {
    setQrCodeDataUrl(dataUrl);
  };
  return (
    <div className="flex-container">
      <Services />
      <div className="main_containers">
        <MainQR />
        <div className="card card-1">
          <div className="header-container">
            <div className="header-left">
              <h2>QR Code Generator for Links</h2>
            </div>
       
          </div>
          <form onSubmit={handleSubmit} className="form-first-container">
            <div className="video_file">
            
              <label htmlFor="location">
                <span>LocationURL</span>
              </label>
              <input
                id="location"
                type="url"
                // multiple
                value={linkData}
                onChange={(e)=>setlinkData(e.target.value)}
                required
              />   
            </div>
            <div className="video_file"> 
              <label htmlFor="folder-name">
                <span>Enter QR code Title</span>
              </label>
              <input
                id="folder-name"
                type="text"
                value={linkName}
                onChange={(e) => setlinkName(e.target.value)}
                required
              />
            </div>
            <div className="button-container">
              <button type="submit" className="submit">
                Submit
              </button>
            </div>
            <ToastContainer />
          </form>
        </div>
      </div>
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onQrCodeUpdate={handleQrCodeUpdate}
          geturl={genURL}
        />
    </div>
  );
}

export default Location;
